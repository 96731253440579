import { vipAxios, Response } from './index';
import { AxiosError } from 'axios';

export interface MeRes {
  data: MeData;
}

export interface MeData {
  id: number;
  brand_id: number;
  realname: string;
  nickname: string;
  mobile_number: string;
  created_at: string | null;
  updated_at: string | null; // 22.10.24 SCH
  line_id: string | null;
  birthday: string | null;
  email: string | null;
  line_pay: number; // 22.10.19 SCH
  user_role: number; // 22.11.23 SCH
  user_type: string | null; // 22.11.23 SCH
  regkey: string | null;
  regkey_expire_at: string | null;
  pay_amount: number; // 逾期14天扣款50元
  common: number;     // [1:一般會員, 0:正式會員]
  photo_url?: string; // 22.07.07 SCH
  photo_img?: [
    {
      originFileObj?: Blob;
      uid: number;
      url: string;
      status: string;
      name?: string;
    },
  ];
}
/**
 * 會員自身資料
 * @param token token
 */
export const getMe = async (token: string): Promise<Response<MeData>> => {
  try {
    const { data } = await vipAxios.request<MeRes>({
      url: `/api/member/me`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log({data}); // 21.12.09 SCH
    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface ModifyResponse<T> {
  error?: AxiosError<{ message: string; errors: { [key: string]: string } }>;
  data?: T;
}

export const modifyMe = async (
    token: string,
    formData: FormData,  // formData: Partial<MeData>, // 22.07.11 SCH
): Promise<ModifyResponse<{ data: {
  data: any; message: string, photo_url: string 
} }>> => {
  try {
    const { data } = await vipAxios.request<{
      data: {
        message: string;
        photo_url: string; // 22.07.11 SCH
        data:any
      };
    }>({
      url: `/api/member/me`,
      method: 'POST', // 22.07.11 SCH, PATCH -> POST
      data: formData,
      headers: {
        // 22.07.11 SCH (已預設!!不必加下面這一行 ContentType...）
        // ContentType: "multipart/form-data; boundary=Nonce",
        Authorization: `Bearer ${token}`,
      },
    });
    return { data };

  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
