/**
 * Liff Context
 *
 * Line liff 的 context
 * 主要為判斷是否已用line登入，並獲取id token 做登入用
 * 與是否在Liff Browser內開啟
 *
 * 正式環境
 * 會Block非Liff Browser開啟網頁的使用者
 *
 * 測試環境
 * 開啟自動導向line login (endopint需配合ngrok之類的套件)
 *
 * API詳情 請參考 @https://developers.line.biz/en/reference/liff/#client-api
 */
import * as PropTypes from 'prop-types';
import {
  Consumer,
  Context,
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react';
import liff from '@line/liff';
import { Liff } from '@line/liff';
// import { useLocation } from 'react-router-dom';

// type Liff = Liff & Liff;

declare global {
  interface Window {
    liff?: Liff;
  }
}
interface LiffProviderProps<T> {
  liffId: string;
  search?: string;
  stubEnabled?: boolean | Partial<T>;
  children?:ReactNode;
}
interface LiffContext {
  error?: unknown;
  isLoggedIn: boolean;
  liff: Liff;
  ready: boolean;
  decodedIdToken?:
    | { picture?: string; name?: string; sub?: string }
    | null
    | undefined;
  idToken: string | null | undefined;
  accessToken: string | null | undefined;
  isInClient: boolean;
}
type CreateLiffContext = <T extends Liff>() => {
  LiffConsumer: Consumer<LiffContext>;
  LiffProvider: FC<LiffProviderProps<T>>;
  useLiff: () => LiffContext;
};

const initLiff = async <T extends Liff>({
  liffId,
  search,
}: LiffProviderProps<T>) => {
  try {
    await liff.init({ liffId });
    const searchParams = new URLSearchParams(search);
    if (searchParams.get('liff.state')) {
      console.log('wait for liff to redirect url');
      await new Promise((resolve) => {});
    }
    return { ready: true };
  } catch (error) {
    console.log(error);
    return { error, ready: false };
  }
};

const LiffProviderPropTypes = {
  children: PropTypes.element.isRequired,
  // liffId: PropTypes.string.isRequired,
};

const createLiffProvider = <T extends Liff>(context: Context<LiffContext>) => {
  const LiffProvider: FC<LiffProviderProps<T>> = ({ children, liffId }) => {
    const [error, setError] = useState<unknown>();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isInClient, setIsInClient] = useState<boolean>(false);
    const [ready, setReady] = useState(false);
    //  const { search } = useLocation();

    useEffect(() => {
      (async () => {
        if (liffId) {
          
          const { error, ready } = await initLiff({
            liffId,
            search: window.location.search
          });
          setError(error);
          // setLiff(liff as T);
          
          if (!liff.isLoggedIn()) {
            if (
              process.env.REACT_APP_ENV !== 'prod' &&
              process.env.REACT_APP_ENV !== 'stage'
            ) {
              liff.login({ redirectUri: window.location.href });
            }
          }
          setIsInClient(liff.isInClient());
          setReady(ready);
          setIsLoggedIn(liff.isLoggedIn());
        } else {
          console.error("doesn't provide liff id");
        }
      })();
      // eslint-disable-next-line
    }, [liffId]);
    
    return (
      <context.Provider
        value={{
          error,
          liff,
          decodedIdToken: isLoggedIn ? liff.getDecodedIDToken() : undefined,
          idToken: isLoggedIn ? liff.getIDToken() : undefined,
          accessToken: isLoggedIn ? liff.getAccessToken() : undefined,
          isInClient,
          isLoggedIn,
          ready,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LiffProvider.propTypes = LiffProviderPropTypes;
  return LiffProvider;
};

export const createLiffContext: CreateLiffContext = () => {
  const context = createContext<LiffContext>({
    isLoggedIn: false,
    liff,
    decodedIdToken: undefined,
    idToken: undefined,
    accessToken: undefined,
    ready: false,
    isInClient: false,
  });
  context.displayName = 'LiffContext';

  return {
    LiffConsumer: context.Consumer,
    LiffProvider: createLiffProvider(context),
    useLiff: () => useContext(context),
  };
};

export const { LiffConsumer, LiffProvider, useLiff } =
  createLiffContext<Liff>();
