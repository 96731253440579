/**
 * Auth Context
 *
 * 使用 Line ID 進行登入
 * 在此判斷會員是否已註冊過
 * 若是已註冊，API會回傳 access Token
 * 若尚未註冊，則會吐回404
 */
import * as PropTypes from 'prop-types';
import {
  Consumer,
  Context,
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react';
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/browser';
import { Login } from '../../api';
import { getMe, MeData } from '../../api/me';
// import { useLiff } from '../Liff';
// 22.04.19 SCH, Add storage4user['meUserStorage']
import { GlobalContext4User } from '../../storage/user';

interface AuthProviderProps {
  lineToken: string;
  children:ReactNode;
}
interface AuthContext {
  error?: any;
  isLoggedIn: boolean;
  logging: boolean;
  isMember: boolean;
  isNormal: boolean;
  accessToken: string | null | undefined;
  auth: (redirect?: string) => void;
  fetching: boolean;
  me?: MeData;
  queryMe?: () => Promise<void>;
}
type CreateAuthContext = () => {
  AuthConsumer: Consumer<AuthContext>;
  AuthProvider: FC<AuthProviderProps>;
  useAuth: () => AuthContext;
};
const AuthProviderPropTypes = {
  children: PropTypes.element.isRequired,
  lineToken: PropTypes.string.isRequired,
  brandID: PropTypes.number.isRequired,
};

const createAuthProvider = (context: Context<AuthContext>) => {
  const AuthProvider: FC<AuthProviderProps> = ({ children, lineToken }) => {
    const [error, setError] = useState<any>();
    const [logging, setLogging] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isMember, setIsMember] = useState<boolean>(false);
    const [isNormal, setIsNormal] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string>();
    const [me, setMe] = useState<MeData>();
    const [fetching, setFetching] = useState<boolean>(false);
    const navigate = useNavigate();
    // const { liff } = useLiff();
    // @ts-ignore // 22.04.19 SCH
    const { meUserStorage } = useContext(GlobalContext4User);
    const { setToken, setUser } = meUserStorage; // 22.04.19 SCH
    const { setLinePay } = meUserStorage; // 22.11.02 SCH
    const { setUserRole } = meUserStorage; // 22.11.23 SCH
    const { setUserType } = meUserStorage; // 22.11.23 SCH
    

    const auth = async (redirect?: string) => {
      try {
        const { error: loginError, data: resp } = await Login(lineToken, 1);
        // console.log(loginError?.message, loginError?.response, resp);
        // console.log('lineToken : ' + lineToken);
        
        if (loginError) {
          if (loginError.response?.status === 401) {
            setError(loginError);
            setIsLoggedIn(false);
            // if (
            //   process.env.REACT_APP_ENV !== 'prod' &&
            //   process.env.REACT_APP_ENV !== 'stage'
            // ) {
            //   liff.logout();
            // }
          } else if (loginError.response?.status === 404) {
            // setError(loginError);
            setIsLoggedIn(false); // 22.02.10 SCH
            setIsNormal(false);
            setIsMember(false);
          } else {
            setError(loginError);
            setIsLoggedIn(false);
          }
          return;
        }
        
        if (resp) {
          const { data } = resp;
          
          //20240105 小白 阻擋沒有手機號碼
          if(data.mobile_number === '' || data.mobile_number === null){
            setIsLoggedIn(false);
            setIsMember(false);
            return
          }

          setAccessToken(data.token);
          setToken(data.token); // 22.04.19 SCH
          setIsLoggedIn(true);
          setIsNormal(data.is_common === 1);

          setIsMember(true);
          // 判斷一般用戶 or 正式會員
          // if (data.is_common === 0) {
          //   setIsMember(true);
          // } else {
          //   setIsMember(false);
          // }

          queryMe(data.token); // 21.12.09 SCH




          //20230201 wilson change
          if (redirect) {
            // navigate(redirect);
            navigate('/');
          }
        }
      } catch (error) {
        setError(error);
        setIsLoggedIn(false);
        setIsMember(false);
        setIsNormal(false);
      }
    };
    
    const queryMe = async (token: string) => {
      try {
        setFetching(true);
        const resp = await getMe(token);
        Sentry.setUser({
          username: `${resp.data?.realname}`,
          id: `${resp.data?.id}`,
          lineId: `${resp.data?.line_id}`,
        });
        // console.log(resp);
        setMe(resp.data);
        setFetching(false);
        setUser(resp.data); // 22.04.19 SCH
        setLinePay(resp.data?.line_pay); // 22.11.02 SCH
        setUserRole(resp.data?.user_role); // 22.11.02 SCH
        setUserType(resp.data?.user_type); // 22.11.02 SCH

      } catch (error) {
        console.log(error);
        setFetching(false);
      }
    };
    useEffect(() => {
      if (lineToken) {
        (async () => {
          setLogging(true);
          await auth();
          setLogging(false);
        })();
      }
      // eslint-disable-next-line
    }, [lineToken]);
    return (
      <context.Provider
        value={{
          error,
          logging,
          accessToken: accessToken,
          isLoggedIn,
          isMember,
          isNormal,
          auth,
          me,
          fetching,
          queryMe: async () => queryMe(accessToken!),
        }}
      >
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  AuthProvider.propTypes = AuthProviderPropTypes;
  return AuthProvider;
};
export const createAuthContext: CreateAuthContext = () => {
  // @ts-ignore
  const context = createContext<AuthContext>({
    isLoggedIn: false,
    accessToken: undefined,
    logging: true,
    isMember: false,
    isNormal: false,
    auth: () => {},
    me: undefined,
    fetching: false,
  });
  context.displayName = 'AuthContext';

  return {
    AuthConsumer: context.Consumer,
    AuthProvider: createAuthProvider(context),
    useAuth: () => useContext(context),
  };
};

export const { AuthConsumer, AuthProvider, useAuth } = createAuthContext();
