import {
  FC,
  // SyntheticEvent,
  // useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from '@mui/material/styles/styled';
import styles from './index.module.scss';
import { useAuth } from 'context/Auth';
import { useNavigate } from 'react-router-dom';// 20230112 wilson add
import { GlobalContext } from '../../storage'; // 21.12.23 SCH



const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  marginTop: 10,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginBottom: 20,
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minWidth: 285,
  padding: '20px',
  paddingBottom: 28,
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 20px',
  paddingBottom: 18,
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-of-type)': {
    marginLeft: 0,
    marginTop: 10,
  },
}));



interface ManualModalErrorProps {
  visible: boolean;
  errorCheck: number;
  onClose?: () => void;
}

const ManualModal: FC<ManualModalErrorProps> = (props) => {
  /* eslint-disable */
  const navigate = useNavigate();// 20230112 wilson add
  const { visible,  errorCheck , onClose } = props;
  const { accessToken, me } = useAuth(); // 21.12.10 SCH
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [packageId, setPackageId] = useState<string>();


  const inputRef = useRef<HTMLInputElement | null>(null); // 21.12.25 SCH
  const [epkIdFound, setEpkIdFound] = useState<boolean>(false);
  const { returnStorage } = useContext(GlobalContext); // 21.12.23 SCH
  const { store_id, setStoreId } = returnStorage; // 21.12.23 SCH
  const { chs_short_code, setChsShortCode } = returnStorage; // 21.12.23 SCH
  const { epk_id, setEpkId } = returnStorage; // 21.12.23 SCH
  const { fami_tage, setFamiTage } = returnStorage; // 20230110 Wilson
  const epkId = useRef<string>(); // 21.12.24 SCH

  const { nearByStorage } = useContext(GlobalContext); // 20230112 wilson add
  const { me_latitude, me_longitude } = nearByStorage;
  /* eslint-enable */ 

  // 20230112 wilson add
  const handleOnConfirmPermissions = () => {

    // 20230112 wilson add  1: camera , 2:position
    switch(errorCheck) {
      case 1:
        return '歸還流程需要[ 同意 ]相機權限，請再次進入歸還流程。';
      case 2:
        return '歸還流程需要[ 同意 ]定位權限，請再次進入歸還流程。(＊如瀏覽器權限被鎖定，會無法執行，請洽客服協助。)';
      default:
        return '授權提示';
    }

  };

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>

       授權提示
      </DialogTitle>
      <DialogContent>
          <div className={styles.manualContainer}>
            <span> { handleOnConfirmPermissions()}</span>
          </div>
      </DialogContent>
      <DialogActions>
        <button
            className={`${styles.actionButton}`}
            onClick={() => {
                  navigate('/rent');
            }}
          >
            確定
          </button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualModal;
