import styles from "./index.module.scss";
import discountInfo from "assets/discountInfo.jpg"; // 22.07.08 SCH
import { useContext, useEffect } from "react"; // 22.05.16 SCH
import { GlobalContext } from "../../storage";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { getVoucherInfo } from "../../api/query";

const ExclusiveDiscount = () => {
  const { accessToken } = useAuth(); // 22.05.16 SCH
  const { shopId } = useParams(); // 抓取網址後面的/{id}
  const { pathname } = useLocation();
  const { nearByStorage } = useContext(GlobalContext);
  const { picture_url, setPictureUrl } = nearByStorage; // 22.05.16 SCH
  const { description, setDescription } = nearByStorage; // 22.05.16 SCH
  const { voucherTitle, setVoucherTitle} = nearByStorage; // 22.05.16 SCH

  useEffect(() => {
    fetchVoucherInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let imgPreUrl = "https://packageplusmainsys.s3-ap-northeast-1.amazonaws.com/";
  let image_url = picture_url ? picture_url.toString() : "null";
  if (image_url.includes("http")) {
    // skip
  } else {
    if (image_url.length > 9) {
      // 22.07.08 SCH
      image_url = imgPreUrl + image_url;
    } else {
      image_url = discountInfo; // 22.07.08 SCH
    }
  }
  console.log(image_url);
  console.log(description);
  let image_description = "";
  image_description = description ? description.toString() : "";
  /* eslint-disable */
  //無使用
  let image_title = "";
  image_title = voucherTitle ? voucherTitle.toString() : "";
  /* eslint-enable */

  const fetchVoucherInfo = async () => {
    try {
      const { data } = await getVoucherInfo(accessToken!, 1, shopId!);
      console.log(data);
      setPictureUrl(data?.picture_url);
      setDescription(data?.description);
      setVoucherTitle(data?.title);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.exclusive}>
      {/* <img src={image_url} alt="couponImage" /> */}
      {/* 20240722小白改 */}
      <img src={discountInfo} alt="couponImage" />
      <div className={styles.description}>
        {/* <h4>{image_title !== '' ? image_title : '目前此通路無專屬優惠'}</h4> */}
        <p>{image_description !== '' ? image_description : '此通路目前無專屬優惠'}</p>
      </div>
    </div>
  );
};

export default ExclusiveDiscount;
