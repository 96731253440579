import { vipAxios, Response } from './index';
// import { AxiosError } from 'axios';

export interface CouponsData {
  id: number;
  title: string;
  description: string;
  start_at: string;
  expired_at: string;
}
/**
 * 取得(線上)電商優惠券(Coupons)!!
 */
export const getCoupons = async (
  token: string,
  brandID: number
): Promise<Response<CouponsData[]>> => {
  try {
    const { data } = await vipAxios.request<CouponsData[]>({
      url: '/api/member/getCoupons',
      method: 'POST',
      data: {
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data }; // 21.12.13 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface ServicePointsData {
  chs_logo_pin_url?: any;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
  service_time: string;
  channel_name: string;
  enable: number;
  store_id: string; // 21.12.23 SCH
  chs_short_code: string; // 21.12.23 SCH
  // markerType: 'box' | 'staff';
  return_type: 'box' | 'staff'; // 22.05.17 SCH
  discount_type: string; // 22.05.16 SCH
  distance: number; // 22.05.19 SCH
  visible: number | boolean; // 22.05.21 SCH ●●●
  week_6sat_ranges?:any;
}
/**
 * 取得服務據點資訊(Info + GPS)!!
 */
export const listServicePoints = async (
  token: string,
  brandID: number,
  userID: number,
  city?: string,
  returnType?: string,
  shop?: string,
  openTime?: string[], // 22.05.15 SCH
  discountType?: string, // 22.05.17 SCH
  latitude?: number, // 20230308 Wilson add
  longitude?: number, // 20230308 Wilson add
): Promise<Response<ServicePointsData[]>> => {
  try {
    const { data } = await vipAxios.request<ServicePointsData[]>({
      url: '/api/member/listServicePoints',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        city: city,
        returnType: returnType,
        shop: shop,
        openTime: openTime, // 22.05.15 SCH
        discountType: discountType, // 22.05.17 SCH
        latitude: latitude, // 20230308 Wilson add
        longitude: longitude, // 20230308 Wilson add
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data }; // 21.12.10 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 20230313 wilson add start
// 為了加速地圖讀取效率，簡化一版限制抓取離ＧＰＳ最近距離４００筆的ＡＰＩ，如果沒有抓到ＧＰＳ位置，則抓取全部資料
export interface RedislistServicePointsData {
  chs_logo_pin_url?: any;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
  service_time: string;
  channel_name: string;
  enable: number;
  store_id: string; // 21.12.23 SCH
  chs_short_code: string; // 21.12.23 SCH
  // markerType: 'box' | 'staff';
  return_type: 'box' | 'staff'; // 22.05.17 SCH
  discount_type: string; // 22.05.16 SCH
  distance: number; // 22.05.19 SCH
  visible: number | boolean; // 22.05.21 SCH ●●●
}
/**
 * 取得服務據點資訊(Info + GPS)!!
 */
export const RedislistServicePoints = async (
  token: string,
  brandID: number,
  userID: number,
  latitude?: number, // 20230112 Wilson add
  longitude?: number, // 20230112 Wilson add
): Promise<Response<FamiServicePointsData[]>> => {
  try {
    const { data } = await vipAxios.request<RedislistServicePointsData[]>({
      url: '/api/member/redislistServicePoints',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        latitude: latitude, // 20230112 Wilson add
        longitude: longitude, // 20230112 Wilson add
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data }; // 21.12.10 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 20230313 wilson add end

// 20230112 wilson add start
// 因應全家通路做一個獨立API
export interface FamiServicePointsData {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
  service_time: string;
  channel_name: string;
  enable: number;
  store_id: string; // 21.12.23 SCH
  chs_short_code: string; // 21.12.23 SCH
  // markerType: 'box' | 'staff';
  return_type: 'box' | 'staff'; // 22.05.17 SCH
  discount_type: string; // 22.05.16 SCH
  distance: number; // 22.05.19 SCH
  visible: number | boolean; // 22.05.21 SCH ●●●
  takeCount?: number,
  chs_logo_pin_url?: any;
}
/**
 * 取得服務據點資訊(Info + GPS)!!
 */
export const familistServicePoints = async (
  token: string,
  brandID: number,
  userID: number,
  shop?: string,
  latitude?: number, // 20230112 Wilson add
  longitude?: number, // 20230112 Wilson add
): Promise<Response<FamiServicePointsData[]>> => {
  try {
    const { data } = await vipAxios.request<FamiServicePointsData[]>({
      url: '/api/member/familistServicePoints',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        shop: shop,
        latitude: latitude, // 20230112 Wilson add
        longitude: longitude, // 20230112 Wilson add
        takeCount: 15 //限制十筆
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data }; // 21.12.10 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 20230112 wilson add end

// 22.04.27 SCH, Add
export interface ChannelsData {
  lavel: string;
  value: string;
}
/**
 * 取得通路清單(lavel,value)!!
 */
export const listChannels = async (
    token: string,
    brandID: number,
    userID: number,
): Promise<Response<ChannelsData[]>> => {
  try {
    const { data } = await vipAxios.request<ChannelsData[]>({
      url: '/api/member/listChannels',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 22.05.16 SCH, Add
export interface VoucherInfoData {
  picture_url: string;
  description: string;
  title:string;
}
/**
 * 取得通路兌換券的(picture_url,description)!!
 */
export const getVoucherInfo = async (
    token: string,
    brandID: number,
    id: string,
): Promise<Response<VoucherInfoData>> => {
  try {
    const { data } = await vipAxios.request<VoucherInfoData>({
      url: '/api/member/getVoucherInfo/' + id,
      method: 'POST',
      data: {
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 22.05.27 SCH, Add
export interface VoucherData {
  redeem_code: string;
  pin_code: string;
  use_type: number;
  start_at: string;
  expired_at: string;
  title: string;
  description: string;
  specification: string;
  picture_url: string; // 22.05.30 SCH
  redeem_url: string; // 22.05.30 SCH
  chs_short_code: string; // 22.05.31 SCH
}
/**
 * 取得通路兌換券的(title,description)!!
 */
export const getVoucher = async (
  token: string,
  brandID: number,
  id: string,
): Promise<Response<VoucherData>> => {
  try {
    const { data } = await vipAxios.request<VoucherData>({
      url: '/api/member/getVoucher/' + id,
      method: 'POST',
      data: {
        brand_id: brandID,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

