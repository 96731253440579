// import zhLocale from 'date-fns/locale/zh-TW';
import { FormHelperText } from '@mui/material';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import AdapterDateFns from '@mui/x-date-pickers';
// import {LocalizationProvider} from '@mui/lab/LocalizationProvider';
// import MobileDatePicker, { MobileDatePickerProps } from '@mui/lab/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers';
import { forwardRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { compareAsc, format } from "date-fns";
// import dayjs from 'dayjs/locale/zh-tw';
// import { DateField } from '@mui/x-date-pickers/DateField';
// import { useState} from 'react';
// import { DatePicker , DatePickerProps} from '@mui/x-date-pickers/DatePicker';

// interface CustomPickerType extends MobileDatePickerProps {
interface CustomPickerType  {
  label: string;
  error?: boolean;
  helperText?: string | boolean;
  value:any;
  onChange:any;
  renderInput?:any
}

const CustomPicker: React.FC<CustomPickerType> = forwardRef((
  props, 
  // ref
) => {
  const { error, helperText, value, onChange, label, ...restProps } = props;
  // console.log(zhLocale)
  // console.log(dayjs)
  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'zh-tw'}> 
      <MobileDatePicker
        {...restProps}
        views={['year', 'month', 'day']}
        // mask="____-__-__"
        label={label}
        format="YYYY-MM-DD"
        value={value}
        onChange={(onChange)}
        localeText={
          {
            // cancelButtonLabel: '取消',
            // okButtonLabel: '確定'
          }
        }
        slotProps={{
                  toolbar:{
                    toolbarFormat:'YYYY年MM月DD日',
                    toolbarPlaceholder:"請選擇日期",
                  },
                  textField: {
                    variant: 'standard',
                    error:error
                  },
                }}
      />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </LocalizationProvider>
  );


});
export default CustomPicker;
