import {
  // GoogleMap,
  useJsApiLoader,
  // Marker as RGMMarker,
  // MarkerProps,
  // InfoBox,
  // MarkerClusterer,
} from "@react-google-maps/api"; // 20230112 wilson add
// import { listServicePoints } from "api/query"; // 20230112 wilsom add

import { Helmet } from "react-helmet";
import { useEffect, useRef, useState, useContext } from "react";
import { useCallback } from "react"; // 20230112 wilson add
import { useNavigate, useParams } from "react-router";
import styles from "./index.module.scss";
// import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import QrReader from "libraries/react-qr-reader";
// import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useAuth } from "context/Auth"; // 21.12.10 SCH

import Dialog from "components/Dialog";
// import { ReactComponent as ResultSVG } from 'assets/result.svg';
import { ReactComponent as ArrowSVG } from "assets/icon-arrow-right.svg";
import { ReactComponent as ShopSVG } from "assets/icon-shop.svg";
// import { ReactComponent as TipSVG } from "assets/image-return.svg";
import { ReactComponent as ReturnBoxSVG } from "assets/image-return-box.svg";
import { ReactComponent as ReturnStaffSVG } from "assets/image-return-staff.svg";
import { ReactComponent as SponserAcerSVG } from "assets/sponsor-acer.svg";
import { ReactComponent as SponserDBSSVG } from "assets/sponsor-dbs.svg";
import { ReactComponent as SponserStarSVG } from "assets/sponsor-star.svg";

import FamiPackagePNG from "assets/fami_package.png"; // 20230113 wilson add
import FamiPageJPG from "assets/fami_page.jpg"; // 20230113 wilson add

import LIFF20GIF from "assets/LIFF20GIF.gif"; // 22.07.08 SCH
import Stepper from "./stepper";
// import { useAuth } from 'context/Auth'; // 21.12.10 SCH
import { getCoupons, CouponsData, ServicePointsData } from "api/query";
import { saveUserRentLogs, Logs1Message } from "api/return";
import { updateUserRentLogs } from "api/return";
import { getChannelStoreBySC, QRCode1ApiData } from "api/return";
import { getEpkType, QRCode2ApiData } from "api/return";
import { chkEpkId } from "api/return"; // 22.02.09 SCH
import { GlobalContext } from "../../storage"; // 21.12.17 SCH
import ManualModal from "./manualModal";
import ManualModalNoGPS from "./manualModalNoGPS";
import ManualError from "./manualError"; // 20230112 wilson add

const Return = () => {
  const { accessToken, me } = useAuth(); // 21.12.10 SCH (wilson 移到最前面)
  /* eslint-disable */
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDBfUhuri4U29ygEqlbyQeL7D5GvvaO0QA",
    // 配客嘉 Google Maps API KEY AIzaSyDgSBCOBJGnaURu38szdsQGcX3r9wziT7o
    // 配客嘉 Google Places API KEY AIzaSyD6XniX3zIWSUIoa0vO7DQZAgzFZK1yUMA
  }); // 20230112 wilson add

  // 20230112 wilson add start

  const { nearByStorage } = useContext(GlobalContext);
  const { setMeLatitude, setMeLongitude } = nearByStorage;
  const { me_latitude, me_longitude } = nearByStorage;
  const { channels, setChannels } = nearByStorage;
  const { spAreaAry, setSpAreaAry } = nearByStorage;
  const { chkDistance } = nearByStorage;
  const { is_area, setIsArea } = nearByStorage;
  const { gps_tage, setGpsTage } = nearByStorage; // 20230110 Wilson
  const { setFamiMeLatitude, setFamiMeLongitude } = nearByStorage; // 20230112 wilson add
  const { fami_me_latitude, fami_me_longitude } = nearByStorage; // 20230112 wilson add
  const { fami_from, setFamiFrom } = nearByStorage; // 20230131 wilson add
  
  // 20230112 wilson add end

  // const vipURL = process.env.REACT_APP_VIP_URL; // 2021.12.10 Mike
  const [step, setStep] = useState<number>(0);
  const [errorCheck, setErrorCheck] = useState<number>(0); // 20230112 wilson add
  const [stopScan, setStopScan] = useState<boolean>(false);
  const [remindVisible, setRemindVisible] = useState<boolean>(false);//自己還幫人環提醒
  const [detailVisible, setDetailVisible] = useState<boolean>(false);//包裝歸還明細
  const [bonusVisible, setBonusVisible] = useState<boolean>(false);//本次獎勵
  const [repeatVisible, setRepeatVisible] = useState<boolean>(false);//重複歸還提醒
  const [freeGiftVisible, setFreeGiftVisible] = useState<boolean>(false); // 20230313 Wilson add 限時額外獎勵
  const [manualVisible, setManualVisible] = useState<boolean>(false);//歸還全家下拉選單
  const [manualNoGPSVisible, setManualNoGPSVisible] = useState<boolean>(false); // 20230112 wilson  add 歸還全家下拉選單(無GPS)
  const [manualErrorVisible, setManualErrorVisible] = useState<boolean>(false); // 20230112 wilson  add 授權提示
  const [currentLocationlVisible, setCurrentLocationVisible] = useState<boolean>(false);//確認掃描包裝編號
  const [done, setDone] = useState<boolean>(false);//確認包裝投進歸還箱
  // 歸還類別： 歸還箱 or 店員 // 22.05.29 SCH
  const [returnType, setReturnType] = useState<"box" | "staff">("box");
  // 獎勵類別： 線上 or 線下 // 22.05.29 SCH
  const [bonusType, setBonusType] = useState<"online" | "offline">("offline");
  const [sponsor, setSponsor] = useState<boolean>(false); //20230525 allen add 贊助開關
  const [srandom, setSrandom] = useState<number>(0); //20230811 allen add 贊助切換
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  // Route.path=":salesChannel/:storeId"; // 21.12.17 SCH
  const { salesChannel, storeId } = useParams();
  const { shortUrl } = useParams(); // 21.12.17 SCH
  // 通路代號 Ref
  const salesChannelRef = useRef<string>();
  // 商店代號 Ref
  const storeIdRef = useRef<string>();
  // 包裝代號 Ref
  const packageIdRef = useRef<string>();
  // lihi-shortUrl Ref // 21.12.16 SCH
  const shortUrlRef = useRef<string>();
  
  // const { accessToken, me } = useAuth(); // 21.12.10 SCH
  const [coupons, setCoupons] = useState<CouponsData[]>();
  const [channelStores, setChannelStores] = useState<QRCode1ApiData>();
  const [epkType, setEpkType] = useState<QRCode2ApiData>();
  const [userRentLogs, setUserRentLogs] = useState<Logs1Message>();
  const coupon_id = useRef<number>(); // 21.12.17 SCH
  const { returnStorage } = useContext(GlobalContext);
  const { channel_name, setChannelName } = returnStorage;
  const { channel_id, setChannelId } = returnStorage;
  const { use_type, setUseType } = returnStorage; // 21.12.21 SCH
  const { store_id, setStoreId } = returnStorage; // 21.12.23 SCH ●●●●●●●●●●
  const { chs_short_code, setChsShortCode } = returnStorage; // 21.12.23 SCH
  const { svpt_name, setSvPtName } = returnStorage;
  const { svpt_id, setSvPtId } = returnStorage;
  const { epk_id, setEpkId } = returnStorage;
  const { type_name, setTypeName } = returnStorage;
  const { date_time, setDateTime } = returnStorage;
  const { renter_id, setRenterId } = returnStorage; // 21.12.22 SCH
  const { fami_tage, setFamiTage } = returnStorage; // 20230112 wilsom add

  const [servicePoints, setServicePoints] = useState<ServicePointsData[]>([]); // 20230112 wilson add

  const mapRef = useRef<google.maps.Map>();

  const stepRef = useRef<number>(0);
  const errorCheckRef = useRef<number>(0);
  const positionCheckRef = useRef<number>(0); // 20230120 wilson add 定位資料 0：沒啟動 , 1: 有啟動

  const [epkIdFound, setEpkIdFound] = useState<boolean>(false);
  const epkId = useRef<string>(); // 22.02.09 SCH

  const [gpsCheck, setGpsCheck] = useState<boolean>(false);

  /* eslint-enable */

  let due_start = "即日起"; // 22.07.06 SCH
  let due_end = "無期限"; // 22.07.06 SCH
  
  // 20230112 wilson add start

  // 執行 GPS 自動定位功能 auto center map base on user's geolocation
  // const getLocation = useCallback(() => {
  const getLocation = useCallback(() => {
    // console.log('getLocation');
    if (!navigator.geolocation) {
      // setStatus('您的瀏覽器並未支援 GPS 自動定位功能。');
      setGpsTage(false);
      setGpsCheck(false);
    } else {
      // setStatus('正在偵測您的位置...');

      // navigator.geolocation.getCurrentPosition(
      const watchId = navigator.geolocation.watchPosition(
        (position) => {

          mapRef?.current?.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          mapRef?.current?.setZoom(16);
          // setDefaultProps({
          //   center: {
          //     lat: position.coords.latitude,
          //     lng: position.coords.longitude,
          //   },
          //   zoom: 16,
          // });
          // 22.02.18 SCH, Saved to storage
          setFamiMeLatitude(position.coords.latitude);
          setFamiMeLongitude(position.coords.longitude);
          setGpsTage(true);
          setGpsCheck(true);
          navigator.geolocation.clearWatch(watchId);
          // 20230120 wilson add 定位資料 0：沒啟動 , 1: 有啟動
          positionCheckRef.current = 1;

          setFamiFrom(Math.random()); // 隨機數，讓全家能自動抓取最近店家
        },
        (error) => {
          setGpsTage(false);
          setGpsCheck(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log(
                "wilson geolocation error",
                "用戶拒絕對獲取地理位置的請求。"
              );
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("wilson geolocation error", "位置信息是不可用的。");
              break;
            case error.TIMEOUT:
              console.log("wilson geolocation error", "請求用戶地理位置超時。");
              break;
            default:
              console.log("wilson geolocation error", "未知錯誤。");
              break;
          }
          // setStatus('無法取得您的所在位置');
          // 20230112 wilson add  1: camera , 2:position
          //暫時先這樣
          // errorCheckRef.current = 2;
          // setErrorCheck(2);
          // setManualErrorVisible(true);

          // 20230120 wilson add 定位資料 0：沒啟動 , 1: 有啟動
          positionCheckRef.current = 0;
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 20230112 wilson add end

  useEffect(() => {
    return () => {
      setStopScan(false); // 22.07.18 SCH
    };
  }, []);

  useEffect(() => {
    // 20230109 wilson, 讓本段程序先停留(1)秒後再執行{...}！

    fetchCoupons(); // 21.12.16 SCH
    //小白 未從輸入電話頁進來，則抓me.id，否則抓改變的renter_id
    if(!state ){
      setRenterId(me?.id!);
    }
    // setRenterId(me?.id!);
    // eslint-disable-next-line
  }, [pathname, me?.id ]);

  useEffect(() => {
    // 20230109 wilson, 讓本段程序先停留(2.5)秒後再執行{...}！
    // setTimeout(() => {

    // 2023.01.09 wilson  add
    // 啟動全家 Fami port 分流 透過 get parameter
    if (storeId === "fami99999") {
      getLocation(); // 20230112 wilson add

      setFamiFrom(Math.random()); // 隨機數，讓全家能自動抓取最近店家
      // 強制讓他顯示全家第一階段模式


      // if(gpsCheck || gps_tage){
      // // if(fami_me_latitude && fami_me_longitude){
      //   // setStatus('無法取得您的所在位置');
      //   // 20230112 wilson add 防呆異常報錯
      //   // 20230112 wilson add  1: camera , 2:position
      //   // errorCheckRef.current = 2;
      //   // setErrorCheck(2);
      //   // setManualErrorVisible(true);

      //   stepRef.current = 0;
      //   setStep(0);
      //   setManualVisible(true);

      // }else{

      //   stepRef.current = 0;
      //   setStep(0);
      //   setManualNoGPSVisible(true);

      //   // stepRef.current = 0;
      //   // setStep(0);
      //   // setManualVisible(true);
      // }

      // if(!fami_me_latitude ||  !fami_me_longitude){
      //   // setStatus('無法取得您的所在位置');
      //   // 20230112 wilson add 防呆異常報錯
      //   // 20230112 wilson add  1: camera , 2:position
      //   errorCheckRef.current = 2;
      //   setErrorCheck(2);
      //   setManualErrorVisible(true);
      // }
      // 20230120 wilson add 為了確保me.id 資料有拿到
      // getLocation();
      // console.log('fami_me_latitude: ' + fami_me_latitude);
      // console.log('fami_me_longitude: ' + fami_me_longitude);
      // console.log('gpsCheck:',gpsCheck);
      // console.log('gps_tage:',gps_tage);
      setTimeout(() => {
        stepRef.current = 0;
        setStep(0);
        setManualVisible(true);
        // setManualNoGPSVisible(true);
      }, 2000);
    }

    if (salesChannel && storeId && storeId !== "fami99999") {
      storeIdRef.current = storeId;
      salesChannelRef.current = salesChannel;
      // 22.02.10 SCH, useEffect()因移除dependencies而[]有兩條黃色底線(OK啦)
      fetchChannelStoreBySC(); // 22.07.20 SCH
      stepRef.current = 1;
      setStep(1);
    } else if (chs_short_code && store_id) {
      storeIdRef.current = store_id;
      salesChannelRef.current = chs_short_code;
      // 22.02.10 SCH, useEffect()因移除dependencies而[]有兩條黃色底線(OK啦)
      fetchChannelStoreBySC(); // 22.07.20 SCH
      stepRef.current = 1;
      setStep(1);
    }

    // }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.id]);

  // 21.12.17 SCH, By Lihi shortUrl 或 salesChannel + storeId 找出 ServicePoint, 二擇一！
  useEffect(() => {
    // 22.02.10 SCH, 這一段不會被執行, 因為 Lihi shortUrl 會自動轉為 liff.line.me 網址
    // 所以上面 useEffect() 的 if (salesChannel && storeId) {} 會為 true 並且搶先執行
    if (shortUrl) {
      // 22.07.06 SCH, 版面會多出一個外框！已不使用！
      shortUrlRef.current = shortUrl; // 21.12.17 SCH
      // 22.02.10 SCH, useEffect()因移除dependencies而[]有兩條黃色底線(OK啦)
      fetchChannelStoreBySC(); // 22.07.20 SCH
      stepRef.current = 1; // Mike
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCoupons = async () => {
    try {
      const { data } = await getCoupons(accessToken!, 1);
      if (!data) {
        // 22.01.05 SCH
        return "Have not any valid coupons !!";
      } else {
        setCoupons(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChannelStoreBySC = async () => {

    try {
      const { data } = await getChannelStoreBySC(accessToken!, 1, {
        chs_short_code: salesChannelRef.current,
        store_id: storeIdRef.current,
        lihi_url: shortUrlRef.current, // 21.12.14 SCH
      });
      setChannelStores(data);
      // 21.12.17 SCH, 將資料存入到 storage !!
      setChannelName(data?.channel_name);
      setChannelId(data?.channel_id);
      setUseType(data?.use_type); // 21.12.21 SCH
      setSvPtName(data?.svpt_name);
      setSvPtId(data?.svpt_id);
      // possible set return type here ?
      setReturnType(data?.return_type!); // 22.05.29 SCH

      // 20230426 wilson add
      if (data?.chs_short_code === "fami") {
        setFamiTage(true);
      }

      if (coupons) {
        // 22.07.06 SCH
        setBonusType("online"); // 22.07.06 SCH
      } else {
        setBonusType("offline"); // 22.07.06 SCH
      }

      setStoreId(storeIdRef.current); // 21.12.23 SCH ●●●●●●●●●
      
      setChsShortCode(salesChannelRef.current); // 21.12.23 SCH
      
      if (data) {
        if (state && state.phone) {

          setCurrentLocationVisible(false); // 22.07.10 SCH
        } else {
          setCurrentLocationVisible(true);
        }
      }
    } catch (error) {
      console.log("getChannelStoreBySC:" + error);
    }
  };

  const fetchEpkType = async () => {
    try {
      const { data } = await getEpkType(accessToken!, 1, {
        epk_id: packageIdRef.current,
      });
      setEpkType(data);
      // 21.12.17 SCH, 將資料存入到 storage !!
      setEpkId(packageIdRef.current);
      setTypeName(data?.type_name);
      setDateTime(data?.date_time);
    } catch (error) {
      console.log(error);
    }
  };

  const addUserRentLogs = async () => {
    try {
      const { data } = await saveUserRentLogs(accessToken!, 1, {
        epk_id: epk_id,
        renter_id: renter_id, // 21.12.23 SCH
        return_user_id: me?.id!, // 21.12.18 SCH
        chs_id: channel_id,
        svpt_id: svpt_id,
        return_type: 0,
      });
      setUserRentLogs(data);
      
      // if (state && state.phone && detailVisible) {
      if (state && state.phone ) {

        //小白加
        if(data?.success){
          setDetailVisible(true);
          setStep(2);
        }else{
          setRepeatVisible(true);
        }

        // 幫別人還 // 22.11.01 SCH
        if (coupons) {
          setBonusType("online");
        } else {
          setBonusType("offline");
        }

      }else{
        //判斷是否一分鐘內重複輸入同樣包裝編號
        if(data?.success){
          setDetailVisible(true);
        }else{
          setRepeatVisible(true);
        }
      }

    } catch (error) {
      console.log(error);
    }
  };

  /* eslint-disable */
  const changeUserRentLogs = async () => {
    
    try {
      const { data } = await updateUserRentLogs(accessToken!, 1, {
        id: userRentLogs?.userRentLog_id,
        userRentLog_id: userRentLogs?.userRentLog_id,
        coupon_id: coupon_id.current,
      });
    } catch (error) {
      console.log(error);
    }
  };
  /* eslint-enable */
  
  useEffect(() => {
    if (state && state.epk_id) {
      setStopScan(true); // 21.12.22 SCH
      packageIdRef.current = epk_id;
    }
  }, [state, epk_id]);

  useEffect(() => {
    if (state && state.phone ) {
      // setStep(2);小白刪
      setStopScan(true); // 21.12.18 SCH
      // setDetailVisible(true);小白刪
      //小白加
      if(renter_id !== me?.id! && renter_id!== undefined){
        addUserRentLogs();
      }
      if(renter_id === me?.id! && renter_id!== undefined){
        addUserRentLogs();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state,renter_id]);
  
  useEffect(() => {
    if (state && state.phone && detailVisible) {
      setChannelStores({
        channel_name: channel_name,
        channel_id: channel_id,
        use_type: use_type, // 21.12.21 SCH
        svpt_name: svpt_name,
        svpt_id: svpt_id,
        chs_short_code: chs_short_code, // 22.02.09 SCH
        store_id: store_id, // 22.02.09 SCH
        return_type: returnType, // 22.05.29 SCH
      });
      packageIdRef.current = epk_id;
      setEpkType({
        type_name: type_name,
        date_time: date_time,
      });
    }
  }, [
    state,
    detailVisible,
    channel_name,
    channel_id,
    use_type,
    svpt_name,
    svpt_id,
    epk_id,
    type_name,
    date_time,
    renter_id,
    pathname,
    chs_short_code,
    store_id,
    returnType,
  ]);

  const onConfirm = () => {
    // 幫自己還
    addUserRentLogs(); // 21.12.10 SCH
    setRemindVisible(false);
    // setDetailVisible(true);小白刪
  };

  const onCancel = () => {
    // 幫別人還
    setRemindVisible(false);
    // 2021.12.10 Mike, 為lihi短網址直接進系統，修改路徑的寫法!!
    let nowPath = pathname;
    
    let words = nowPath.split("/");
    //小白加 重置renter_id
    setRenterId();
    if (words.length === 2) {
      navigate("./other");
    } else {
      if (words.length === 3) {
        navigate("other");
      }
      if (words.length === 4) {
        navigate("../other");
      }
      if (words.length === 5) {
        navigate("../../other");
      }
      if (words.length === 6) {
        navigate("../../../other");
      }
    }
  };

  const onDetailConfirm = () => {
    if (state && state.phone && detailVisible) {
      fetchCoupons(); // 22.11.01 SCH, 重抓!!
      // addUserRentLogs(); // 小白刪
    }
    setStep(2);
    // setStopScan(true); // 21.12.21 Mike
    setDetailVisible(false);
    setDone(true);
    setSponsor(true); //20230525 allen add

    setSrandom(Math.floor(Math.random() * 2)); //20230811 allen add
    
    // 21.12.24 SCH, 讓「歸還成功」的畫面停留兩秒再切換「對話框」！
    // setTimeout(() => {
    //   setBonusVisible(true);
    // }, 2000);
  };

  const findEpkId = async () => {
    try {
      const { data } = await chkEpkId(accessToken!, 1, {
        epk_id: epkId.current,
      });
      if (data) {
        console.log(data);
        // 22.02.09 SCH, 將資料存入到 storage !!
        console.log("epk_id：" + data?.epk_id);
        setEpkId(data?.epk_id); // 22.02.09 SCH
        setEpkIdFound(true);
      } else {
        setEpkIdFound(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 20230313 wilson change 增加 free gift 設計
  // 通路禮物與據點禮物控制開關，如果有據點禮物，則不會得到通路禮物。

  const onBonusConfirm = () => {
    setBonusVisible(false);
    setFreeGiftVisible(true); // 20230313 wilson 新增
    // navigate('/discount/offline');
  };

  // 20230313 wilson add 增加 free gift 設計
  // 電商禮券開關，每次必定會拿到
  const onFreeGiftConfirm = () => {
    setFreeGiftVisible(false);
    navigate("/discount");
  };

  const handleError = (err: any) => {
    // 20230112 wilson add  1: camera , 2:position
    // 相機開啟失敗，啟動建議。
    errorCheckRef.current = 1;
    setErrorCheck(1);
    setManualErrorVisible(true);
    console.error(err);
  };
  const handleScan = async (result: any) => {
    /* eslint-disable */
    // 20230112 wilson add 限制step1 相機掃的URL規格
    const returnSpotRegex =
      /https:\/\/liff\.line\.me\/(?<liffId>[^\/\s]+)\/return\/(?<salesChannel>[^\/\s]+)\/(?<storeId>[^\/\s]+)/;
    const lihiRegex = /https:\/\/lihi1\.com\/(?<shortUrl>[^\/\s]+)/;
    /* eslint-enable */
    
    if (result && !stopScan) {
      if (stepRef.current === 0) {
        // const [,shortUrl] 只會取到 hash, const [shortUrl]會包含 https 跟網域
        const [shortUrl] = result.match(lihiRegex) || []; // 要加 || [] 21.12.14 SCH
        shortUrlRef.current = shortUrl; // 21.12.15 Mike
        console.log("lihi shortUrl: " + shortUrlRef.current);
        // Lihi shortUrl
        const [, , salesChannel, storeId] = result.match(returnSpotRegex) || [];
        if (shortUrl) {
          shortUrlRef.current = shortUrl; // 21.12.15 Mike
          // 已取得 lihi短網址 並切換step至(1)取得包裝ID
          fetchChannelStoreBySC(); // 21.12.14 SCH
        } else {
          // 20230113 wilson add fami port 進入連結
          // 鏡頭掃描進入
          if (storeId === "fami99999") {
            getLocation(); // 20230112 wilson add
            // console.log("fami_me_latitude: " + fami_me_latitude);
            // console.log("fami_me_longitude: " + fami_me_longitude);

            setFamiFrom(Math.random()); // 隨機數，讓全家能自動抓取最近店家

            // console.log("2");
            // console.log("salesChannel: " + salesChannel);
            // console.log("storeId: " + storeId);

            setTimeout(() => {
              stepRef.current = 0;
              setStep(0);
              setManualVisible(true);
              // setManualNoGPSVisible(true);
            }, 1500);
          }

          if (salesChannel && storeId && storeId !== "fami99999") {
            salesChannelRef.current = salesChannel;
            storeIdRef.current = storeId;
            // console.log("salesChannel: " + salesChannel);
            // console.log("storeId: " + storeId);
            // 已取得 通路代號(salesChannel) 與 商店ID (sotreID)
            fetchChannelStoreBySC(); // 21.12.11 SCH
          }
        }
      }
      if (stepRef.current === 1) {
        if (result.length >= 6 && result.indexOf("https") < 0) {
          if (!channel_name || !svpt_name) {
            fetchChannelStoreBySC();
          }
          // 22.02.10 SCH, 讓本段程序先停留(0.1)秒後再執行{...}！
          setTimeout(() => {
            epkId.current = result; // 22.02.09 SCH
            findEpkId(); // 22.02.09 SCH
            if (epkIdFound) {
              // 22.02.09 SCH
              packageIdRef.current = result;
              stepRef.current = 2;
              setRemindVisible(true);
              // 已取得 包裝代號(packageId)
              fetchEpkType(); // 21.12.11 SCH
            }
          }, 100);
        }
      }
    }
  };
  const onCurrentLocationConfirm = () => {
    stepRef.current = 1;
    setStep(1);
  };

  // 20230111 wilson add
  const onManualConfirm = (data?: ServicePointsData | string) => {
    if (step === 0) {
      salesChannelRef.current = chs_short_code;
      storeIdRef.current = store_id; // 21.12.24 SCH
      shortUrlRef.current = "noUrl"; // 22.02.09 SCH
      fetchChannelStoreBySC(); // 21.12.24 SCH
      stepRef.current = 1;
      setManualVisible(false);
      setStep(1);
    }
    if (step === 1) {
      if (!channel_name || !svpt_name) {
        // 22.02.09 SCH
        fetchChannelStoreBySC();
      }
      packageIdRef.current = epk_id;
      fetchEpkType(); // 21.12.24 SCH
      stepRef.current = 2;
      setManualVisible(false);
      setRemindVisible(true);
    }
  };

  // 20230111 wilson add
  const onManualNoGPSConfirm = (data?: ServicePointsData | string) => {
    if (step === 0) {
      salesChannelRef.current = chs_short_code;
      storeIdRef.current = store_id; // 21.12.24 SCH
      shortUrlRef.current = "noUrl"; // 22.02.09 SCH
      fetchChannelStoreBySC(); // 21.12.24 SCH
      stepRef.current = 1;
      setManualNoGPSVisible(false);
      setStep(1);
    }
    if (step === 1) {
      if (!channel_name || !svpt_name) {
        // 22.02.09 SCH
        fetchChannelStoreBySC();
      }
      packageIdRef.current = epk_id;
      fetchEpkType(); // 21.12.24 SCH
      stepRef.current = 2;
      setManualVisible(false);
      setRemindVisible(true);
    }
  };

  return (
    <>
      <Helmet title="歸還" />
      {sponsor ? (
        <div className={styles.return}>
          <div className={styles.sponsor}>
            <div className={styles.mainpic}>
              {srandom === 0 ? <SponserAcerSVG /> : <SponserDBSSVG />}
              {/* <SponserAcerSVG /> */}
            </div>
            <div className={styles.maincontent}>
              <div className={styles.sponsorcon}>
                <p>
                  <span>PackAge+ </span>
                  與社福基金會合作包裝清潔
                </p>
                <p>提供弱勢朋友穩定的工作機會與收益！</p>
              </div>
              <div className={styles.sponsortag}>
                <SponserStarSVG />
                <p>
                  本次由{" "}
                  <span className={styles.tagUnder}>
                    {srandom === 0 ? "宏碁股份有限公司" : "星展銀行"}
                    {/* 宏碁股份有限公司 */}
                  </span>{" "}
                  響應贊助
                </p>
                <SponserStarSVG />
              </div>
              <div
                className={styles.button}
                onClick={() => {
                  setSponsor(false);
                }}
              >
                下一步{" "}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.return}>
          {done ? (
            <div className={styles.result}>
              {/* 20230112 wilson add */}
              {fami_tage ? (
                <img
                  style={{ width: 360, height: 41.84, left: 7, top: 96 }}
                  src={FamiPackagePNG}
                  alt="PNG"
                />
              ) : (
                <p></p>
              )}
              {/* 20230112 wilson add */}
              {fami_tage ? (
                <img
                  style={{ width: 270, height: 270, left: 52, top: 152 }}
                  src={FamiPageJPG}
                  alt="PNG"
                />
              ) : returnType === "box" ? (
                <ReturnBoxSVG />
              ) : (
                <ReturnStaffSVG />
              )}

              <div
                className={styles.button}
                onClick={() => {
                  // setBonusVisible(true);
                  // setFreeGiftVisible(false); // 20230313 Wilson add
                  // 20230320 wilson note 這邊開始分流
                  // 20230320 wilson add 如果抓不到通路或服務據點的優惠活動，則不會顯示setDetailVisible
                  if (userRentLogs?.svpt_info) {
                    setBonusVisible(true);
                    setFreeGiftVisible(false); // 20230313 Wilson add
                  } else {
                    setBonusVisible(false);
                    setFreeGiftVisible(true); // 20230313 Wilson add
                  }
                }}
              >
                <div>
                  <span>
                    {returnType === "box"
                      ? "包裝投進歸還箱了"
                      : "包裝交給店員了"}
                  </span>
                </div>
                <ArrowSVG />
              </div>
            </div>
          ) : (
            <div className={styles.cameraContainer}>
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "100%", height: "100%" }}
                showViewFinder
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
                viewFinder={
                  <div className={styles.scannerContainer}>
                    <div className={styles.scannerInner} />
                  </div>
                }
              />
              {step === 1 && (
                <div className={styles.selectedStore}>
                  <ShopSVG />
                  <span>
                    {channel_name}-{svpt_name}
                  </span>
                </div>
              )}
              {step === 2 && (
                <div className={styles.selectedStore}>
                  <ShopSVG />
                  <span>{epk_id}</span>
                </div>
              )}
            </div>
          )}
          <div className={styles.footer}>
            <Stepper step={step} />

            {step === 1 && (
              <button
                style={step === 1 ? { opacity: 1 } : { opacity: 0 }}
                onClick={() => {
                  setManualVisible(true);
                }}
              >
                無法掃描？
              </button>
            )}
          </div>
        </div>
      )}
      <Dialog
        // visible={repeatVisible}
        visible={repeatVisible}
        
        // visible={true}
        onConfirm={()=>{setRepeatVisible(false)}}
        message={
          <div className={styles.dialogHeader}>
            <h5>提醒</h5>
            <p>
              您輸入的包裝編號已在系統中
              <br />
              成功歸還，請將包裝交給店員
              <br />
              或投入歸還箱。
              <br />
              <br />
              若您取得多個同樣包裝編號的循環包裝，
              <br />
              請稍候一分鐘再試。
              <br />
            </p>
            {/* <button
              className={styles.okButtonStyles2}
              onClick={() => {
                setRepeatVisible(false)
              }}
            >
              <span>我知道了</span>
            </button> */}
          </div>
        }
        // showOk={false}
        showOk={true}
        // okText="我知道了"
      />
      <Dialog
        visible={remindVisible}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message={
          <div className={styles.dialogHeader}>
            <h5>提醒</h5>
            <p>
              請問您是自己歸還，
              <br />
              還是幫家人、朋友歸還包裝呢？
              <br />
            </p>
            <p>包裝編號：{epk_id}</p>
          </div>
        }
        cancelText="我幫別人還"
        okText="我幫自己還"
        cancelable
      />
      <Dialog
        visible={detailVisible}
        onConfirm={onDetailConfirm}
        message={
          <div className={styles.detailDialogHeader}>
            <h5>包裝歸還明細</h5>
            <div className={styles.content}>
              <div>
                <span>歸還時間</span>
                {/* <span>{returnTime.current}</span> */}
                <span>{epkType?.date_time}</span>
              </div>
              <div>
                <span>歸還地點</span>
                {/* <span>{salesChannelRef.current}</span> */}
                <span>
                  {channelStores?.channel_name}-{channelStores?.svpt_name}
                </span>
              </div>
              <div>
                <span>包裝類別</span>
                {/* <span>{packageIdRef.current}</span> */}
                <span>{epkType?.type_name}</span>
              </div>
              {state && state.phone && (
                <>
                  <div>
                    <span>歸還者</span>
                    {/* <span>{state.phone}</span> */}
                    <span>
                      {me!.realname !== "" ? me!.realname : me!.mobile_number}
                    </span>
                  </div>
                  <div>
                    <span>使用者</span>
                    <span>{state.phone}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        }
        okText="我知道了"
        okButtonStyles={styles.okButtonStyles}
      />
      <Dialog
        visible={currentLocationlVisible}
        onConfirm={onCurrentLocationConfirm}
        onBackdropClick={() => {
          setCurrentLocationVisible(false);
        }}
        message={
          <div className={styles.currentLocation}>
            <p className={styles.title}>您目前在</p>
            <p className={styles.shopName}>
              {channelStores?.channel_name}
              {channelStores?.svpt_name}
            </p>
            <div>
              {/*<TipSVG />*/}
              <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={LIFF20GIF}
                alt="gif"
              />
            </div>
            <div className={styles.tip}>
              <p>接下來請掃描</p>
              <p>「包裝編號」QR Code</p>
            </div>
            <button
              className={styles.okButtonStyles}
              onClick={() => {
                setCurrentLocationVisible(false);
                onCurrentLocationConfirm(); // 22.07.10 SCH
              }}
            >
              <div className={styles.otherComfirm} style={{ fontSize: "18px", color: "green" }}>
                <span className={styles.com1}>「確認」</span>
                <span className={styles.com2}>
                  <ArrowSVG />
                </span>
              </div>
            </button>
          </div>
        }
        showOk={false}
      />
      <Dialog
        visible={bonusVisible}
        onConfirm={onBonusConfirm}
        message={
          <div className={styles.bonusDialogHeader}>
            {/* <p>限時額外獎勵！電商優惠券三選一</p> vip v2.0 之後就改為全部領取!? */}
            <h5>{"本次獎勵"}</h5>
            <div className={styles.content}>
              {/* <h1>{userRentLogs?.svpt_info}</h1>
              <h3>{coupons ? '' : '抱歉！優惠券皆已過期'}</h3> */}
              {
                <div>
                  <div>
                    <h2>{channelStores?.svpt_name}</h2>
                    <h2>{userRentLogs?.svpt_info}</h2>
                  </div>
                  <button
                    className={styles.collectAllBtn}
                    onClick={() => {
                      onBonusConfirm();
                      // navigate('/discount/');
                    }}
                  >
                    <div>
                      <span>知道了</span>
                    </div>
                    <ArrowSVG />
                  </button>
                </div>
              }
            </div>
            {/* <button
              className={styles.collectAllBtn}
              onClick={() => {
                setTimeout(() => {
                  // 22.07.20 SCH, 還原 !! coupon
                  // online: collect coupon here
                  setTimeout(() => {
                    changeUserRentLogs(); // 21.12.26 SCH
                  }, 1000);
                  navigate('/discount/');
                  // navigate('/discount/ecommerce');
                }, 500);
              }}
            >
              <div>
                <span>{bonusType === 'online' ? '全部領取' : '領取'}</span>
              </div>
              <ArrowSVG />
            </button> */}
            <p>本次獎勵優惠券將發放至您的「優惠」頁面</p>
          </div>
        }
        showOk={false}
        okButtonStyles={styles.okButtonStyles}
      />

      {/* 202300313 Wilson add  freeGift 設計*/}
      <Dialog
        visible={freeGiftVisible}
        onConfirm={onFreeGiftConfirm}
        message={
          <div className={styles.bonusDialogHeader}>
            {/* <p>限時額外獎勵！電商優惠券三選一</p> vip v2.0 之後就改為全部領取!? */}
            <h5>{"限時額外獎勵"}</h5>
            <div className={styles.content}>
              {/* <h1>{userRentLogs?.svpt_info}</h1>
              <h3>{coupons ? '' : '抱歉！優惠券皆已過期'}</h3> */}
              {
                <div className={styles.list}>
                  {coupons?.map((item) => (
                    <div className={styles.listItem} key={item.id}>
                      <div className={styles.coupon}>
                        <div className={styles.infoContainer}>
                          <span className={styles.title}>{item.title}</span>
                          <span className={styles.date}>
                            {item.start_at === null
                              ? due_start
                              : item.start_at.slice(0, 10)}
                            ~
                            {item.expired_at === null
                              ? due_end
                              : item.expired_at.slice(0, 10)}
                          </span>
                        </div>
                        <div className={styles.btnContainer}>
                          <button
                            onClick={() => {
                              coupon_id.current = item.id; // 21.12.17 SCH
                              // 21.12.26 SCH, 讓本「Dialog」的畫面停留一秒再執行 changeUserRentLogs()！
                              setTimeout(() => {
                                changeUserRentLogs(); // 21.12.26 SCH
                              }, 1000);
                              setTimeout(() => {
                                onFreeGiftConfirm();
                                // navigate('/discount/');
                                // navigate('/discount/ecommerce');
                              }, 500);
                            }}
                          >
                            領取
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
            {/* <button
              className={styles.collectAllBtn}
              onClick={() => {
                setTimeout(() => {
                  // 22.07.20 SCH, 還原 !! coupon
                  // online: collect coupon here
                  setTimeout(() => {
                    changeUserRentLogs(); // 21.12.26 SCH
                  }, 1000);
                  navigate('/discount/');
                  // navigate('/discount/ecommerce');
                }, 500);
              }}
            >
              <div>
                <span>{bonusType === 'online' ? '全部領取' : '領取'}</span>
              </div>
              <ArrowSVG />
            </button> */}
            <p>本次獎勵優惠券將發放至您的「優惠」頁面</p>
          </div>
        }
        showOk={false}
        okButtonStyles={styles.okButtonStyles}
      />
      <ManualModal
        visible={manualVisible}
        step={step}
        onConfirm={onManualConfirm}
        onClose={() => {
          setManualVisible(false);
        }}
      />
      <ManualModalNoGPS
        visible={manualNoGPSVisible}
        step={step}
        onConfirm={onManualNoGPSConfirm}
        onClose={() => {
          setManualNoGPSVisible(false);
        }}
      />
      <ManualError
        visible={manualErrorVisible}
        errorCheck={errorCheck}
        onClose={() => {
          setManualErrorVisible(false);
        }}
      />
    </>
  );
};

export default Return;
