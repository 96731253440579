import { Helmet } from 'react-helmet';
import { useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import styles from './index.module.scss';
import { useAuth } from 'context/Auth'; // 21.12.17 SCH
import { chkOtherUser } from 'api/return'; // 21.12.18 SCH
import { GlobalContext } from "../../storage"; // 21.12.18 SCH

const ReturnForOther = () => {
  const [phone, setPhone] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { accessToken} = useAuth(); // 21.12.17 SCH
  const { returnStorage } = useContext(GlobalContext); // 21.12.18 SCH
  const { renter_id, setRenterId } = returnStorage; // 21.12.18 SCH
  
  console.log(renter_id);

  const fetchOtherUser = async () => {
    try {
      const { data } = await chkOtherUser(accessToken!, 1, {
        mobile_number: phone,
      });
      setRenterId(data?.id);
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {

    navigate('/return', {
      state: {
        phone,
      },
    });
  };


  return (
    <>
      <Helmet title="我幫別人還" />
      <div className={styles.returnForOther}>
        <h5>請輸入循環包裝使用者的手機號碼</h5>
        <h6>請確保號碼輸入正確，以利後續歸還作業</h6>
        <input
          type="tel"
          pattern="[0-9]*"
          ref={inputRef}
          onChange={(event) => {
            setPhone(event.target.value as any);
          }}
        />
        <button
          onClick={() => {
            fetchOtherUser();
            handleNext();
          }}
        >
          下一步
        </button>
      </div>
    </>
  );
};

export default ReturnForOther;
