import { useMemo } from "react";
import { BrowserRouter,
  // HashRouter 
} from "react-router-dom";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@mui/material/styles";
import styles from "./App.module.scss";
import { useLiff } from "context/Liff";
import { Helmet } from "react-helmet";
import { AuthProvider } from "context/Auth";
import CustomRoutes from "pages/Routes";
import { GlobalProvider } from "./storage"; // 21.12.17 SCH (放在第六層)
import { UserProvider } from "./storage/user"; // 22.04.19 SCH (放在第二層)

const AppWrapper = () => {
  const { 
    idToken, 
    // decodedIdToken, 
    ready, 
    error, 
    // isInClient 
  } = useLiff();
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: "#08bf5b",
          },
          error: {
            main: "#f44336",
          },
        },
        components: {
          MuiInput: {
            styleOverrides: {
              root: {
                width: "100%",
                padding: "8px 0px",
              },
              input: {
                fontSize: 17,
                letterSpacing: 0.34,
                height: 24,
                margin: 0,
                padding: 0,
                color: "#4a494a",
              },
              underline: {
                "&:before": {
                  borderWidth: "1px !important",
                  borderColor: "#00000033",
                },
                "&:after": {
                  borderWidth: 1,
                },
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                width: "100%",
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: "#00000033",
                top: -8,
              },
              formControl: {
                transform: "translate(0, 32px) scale(1)",
              },
              shrink: {
                transform: "translate(0, 1.5px) scale(0.75)",
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: "#00000033",
              },
            },
          },
        },
      }),
    // eslint-disable-next-line
    []
  );
  // Line Liff 出現錯誤 或尚未 初始化成功

  if (!ready || error) {
    return null;
  }
  
  // Line Liff 使用者未使用Line App登入，將會自動屏蔽
  // if ( !isInClient) {
  //   return null;
  // }

  // Line Liff 未登入
  // if (!isLoggedIn) {
  //   return <LoginFailed />;
  // }
  // console.log("Line_IdToken");
  // console.log(decodedIdToken);
  
  return (
    <BrowserRouter>
      <UserProvider>
        <AuthProvider lineToken={idToken!}>
          <ThemeProvider theme={theme}>
            <Helmet title="PackAge+" />
            <div className={styles.container}>
              <GlobalProvider>
                <CustomRoutes />
              </GlobalProvider>
            </div>
          </ThemeProvider>
        </AuthProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default AppWrapper;
