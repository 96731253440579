import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';
import { ReactComponent as TakeSVG } from 'assets/icon-take.svg';
import { ReactComponent as MapSVG } from 'assets/icon-map.svg';
import { ReactComponent as HomeSVG } from 'assets/icon-home.svg';
import { ReactComponent as CouponSVG } from 'assets/icon-coupon.svg';
import { ReactComponent as MemberSVG } from 'assets/icon-member.svg';
import { useState } from 'react';
export type MenuItem = {
  id?: string;
  icon?: (color: string) => JSX.Element | null;
  title?: string;
  url?: string;
  onClick?: () => void;
  exact?: boolean;
};

const MenuTabBar = () => {
  // eslint-disable-next-line
  const [menu, setMenu] = useState<MenuItem[]>([
    {
      icon: (color: string = '#999') => <TakeSVG fill={color} />,
      title: '租借/歸還',
      url: '/rent',
      exact: false,
    },
    {
      icon: (color: string = '#999') => <MapSVG fill={color} />,
      title: '地圖',
      url: '/nearby',
      exact: true,
    },
    {
      icon: (color: string = '#999') => <HomeSVG fill={color} />,
      title: '主頁',
      url: '/',
      exact: true,
    },
    {
      icon: (color: string = '#999') => <CouponSVG fill={color} />,
      title: '優惠',
      url: '/discount/ecommerce',
      exact: true,
    },
    {
      icon: (color: string = '#999') => <MemberSVG fill={color} />,
      title: '會員中心',
      url: '/member/return',
      exact: true,
    },
  ]);

  return (
    <>
      <div className={styles.menuTabBar}>
        {menu.map((menu, index) => (
          <NavLink
            to={{ pathname: menu.url }}
            className={({ isActive }) =>
              isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem
            }
            // key={menu.id}
            key={index}
          >
            <div>{menu.icon && menu.icon('#999')}</div>
            <span>{menu.title}</span>
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default MenuTabBar;
