import {useEffect, useRef, useState} from 'react';
import { format, isBefore, isSameDay, isAfter } from 'date-fns';
import { ReactComponent as PinExpiredSVG } from 'assets/pin-expired.svg';
import { ReactComponent as PinCloseSVG } from 'assets/pin-close.svg';
import { ReactComponent as PinUsingeSVG } from 'assets/pin-using.svg';
import styles from './index.module.scss';
// 22.04.22 SCH
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/Auth';
import { listRentDataLogs, Logs1Type } from 'api/logs';

// const Mock = [
//   {
//     id: 1,
//     date: new Date().toISOString(),
//     location: '配客嘉 線上商店',
//     user: 'Kennedy',
//     expiredAt: '2021-01-08T11:09:50.000000Z',
//   },
//   {
//     id: 2,
//     date: '2021-01-01T10:09:50.000000Z',
//     location: '配客嘉 線上商店',
//     user: 'Kennedy',
//     expiredAt: '2022-08-08T11:09:50.000000Z',
//   },
//   {
//     id: 3,
//     date: '2021-01-01T10:09:50.000000Z',
//     location: '配客嘉 線上商店',
//     user: 'Kennedy',
//     expiredAt: '2021-01-08T11:09:50.000000Z',
//   },
// ];

const isSameOrBefore = (date: Date, comparDate: Date) =>
  isBefore(date, comparDate) || isSameDay(date, comparDate);
const isSameOrAfter = (date: Date, comparDate: Date) =>
  isAfter(date, comparDate) || isSameDay(date, comparDate);

const getStatus = (startDate: string, expiredDate: string) => {
  // if (isSameOrBefore(new Date(), new Date(startDate))) {
  //   return 'using';
  // }
  if (isSameOrBefore(new Date(), new Date(expiredDate)) && isSameOrAfter(new Date(), new Date(startDate))) {
    return 'using';
  }
  // if (isSameOrAfter(new Date(), new Date(expiredDate))) {
  //   return 'expired';
  // }
  if (isAfter(new Date(), new Date(expiredDate))) {
    return 'expired';
  }
  // TODO: add your logic of close to expired date
  return 'close'; // 22.05.14 SCH, 未到可使用的日期 ...
};

const UsedHistory = () => {
  const [filter, setFilter] = useState<'all' | 'using' | 'expired'>('all');
  // const [sort, setSort] = useState<'ascend' | 'descend'>('descend');
  const { accessToken, me } = useAuth();
  const { pathname } = useLocation();
  const [logs, setLogs] = useState<Logs1Type[]>();
  const filterRef = useRef<string>('all'); // 22.05.30 SCH, <'all' | 'using' | 'expired'>
  const sortRef = useRef<string>('descend'); // 22.05.30 SCH

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line
  }, [pathname]);

  const fetchLogs = async () => {
    try {
      const { data } = await listRentDataLogs(
          accessToken!, 1, me?.id!,
          filterRef.current, sortRef.current,
      );
      if (!data) {
        return 'Have not any rent logs !!';
      } else {
        setLogs(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateStr: string) => {
    if (dateStr.indexOf('T') > 0) {
      let newDate = format(new Date(dateStr), 'yyyy-MM-dd');
      return newDate; // 22.05.29 SCH
    }
    return dateStr;
  };

  return (
    <div className={styles.history}>
      <div className={styles.filter}>
        <button
          className={filter === 'all' ? styles.active : undefined}
          onClick={() => {
            setFilter('all');
            filterRef.current = 'all';
            fetchLogs(); // 23.01.05 SCH
          }}
        >
          全部
        </button>
        <button
          className={filter === 'using' ? styles.active : undefined}
          onClick={() => {
            setFilter('using');
            filterRef.current = 'using';
            fetchLogs(); // 23.01.05 SCH
          }}
        >
          使用中
        </button>
        <button
          className={filter === 'expired' ? styles.active : undefined}
          onClick={() => {
            setFilter('expired');
            filterRef.current = 'expired';
            fetchLogs(); // 23.01.05 SCH
          }}
        >
          已逾期
        </button>
      </div>
      <div className={styles.list}>
        {logs?.map((item) => {
          const status = getStatus(item.created_at, item.expired_at);
          return (
            <div className={styles.listItem} key={item.id}>
              <div
                className={`${styles.status} ${
                  status === 'using'
                    ? styles.using
                    : status === 'close'
                    ? styles.close
                    : styles.expired
                }`}
              >
                <div className={styles.startDate}>
                  <span>起始日</span>
                  <span>{formatDate(item.shipping_at)}</span>
                </div>
                <div className={styles.line}>
                  {status === 'using' ? (
                    <PinUsingeSVG />
                  ) : status === 'close' ? (
                    <PinCloseSVG />
                  ) : (
                    <PinExpiredSVG />
                  )}
                </div>
                <div className={styles.expireDate}>
                  <span>到期日</span>
                  <span>{formatDate(item.expired_at)}</span>
                </div>
              </div>
              <div className={styles.detail}>
                <div>
                  <span>訂單編號</span>
                  <span>{item.eco_short_code}</span>
                </div>
                <div>
                  <span>訂單來源</span>
                  <span>{item.ec_partner_name}</span>
                </div>
                <div>
                  <span>使用者</span>
                  <span>{item.renter_name}</span>
                </div>
                <div>
                  <span>到期時間</span>
                  <span>
                    {formatDate(item.expired_at)}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <span className={styles.tip}>僅顯示最近3個月內的記錄</span>
    </div>
  );
};

export default UsedHistory;
