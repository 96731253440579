import { useEffect, useContext } from 'react';
import { ReactComponent as HomeSVG } from 'assets/icon-navigation.svg';
import styles from './index.module.scss';
// import { ServicePointsData } from 'api/query';
import { GlobalContext } from '../../storage'; // 22.02.18 SCH

const BackToHome = ({ onPress }: { onPress: () => void }) => {
  const { nearByStorage } = useContext(GlobalContext); // 22.02.18 SCH
  /* eslint-disable */
  const { me_latitude, me_longitude } = nearByStorage; // 22.02.18 SCH
  /* eslint-enable */
  // console.log(me_latitude,me_longitude );
  
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const back2home = () => {
    onPress();
  };

  return (
    <div className={styles.toHomeContainer}>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => {
            back2home();
          }}
        >
          <HomeSVG />
        </button>
      </div>
    </div>
  );
};

export default BackToHome;
