import React, { forwardRef, useState } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import TextField from 'components/TextField';
import { IMaskInput } from 'react-imask';
import styles from './index.module.scss';
import SubmitButton from 'components/SubmitButton';
import Dialog from 'components/Dialog';
import { useNavigate } from 'react-router';

const CardNumbeMask = forwardRef(function TextMaskCustom(props: InputBaseComponentProps, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}

      mask="0000 0000 0000 0000"
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: any) =>
        onChange &&
        onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement>)
      }
      overwrite
    />
  );
});
const ExpireMask = forwardRef(function TextMaskCustom(props: InputBaseComponentProps, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}

      mask="00/00"
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: any) =>
        onChange &&
        onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement>)
      }
      overwrite
    />
  );
});
const CVVMask = forwardRef(function TextMaskCustom(props: InputBaseComponentProps, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}

      mask="000"
      // @ts-ignore
      inputRef={ref}
      onAccept={(value: any) =>
        onChange &&
        onChange({ target: { name: props.name, value } } as React.ChangeEvent<HTMLInputElement>)
      }
      overwrite
    />
  );
});

type FormData = {
  cardNumber?: string;
  expire?: string;
  cvv?: string;
};

const CreditCard = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({});
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const onConfirm = () => {
    setVisible(false);
    navigate('/payment', {
      state: {
        mock: true,
      },
    });
  };
  const onSubmit = () => {
    setVisible(true);
  };
  const isDisabled = () => {
    if (formData.cardNumber && formData.expire && formData.cvv) {
      return false;
    }
    return true;
  };
  return (
    <>
      <div className={styles.paymentSetting}>
        <div>
          <h1>卡片資訊</h1>
        </div>

        <div className={styles.form}>
          <TextField
            value={formData.cardNumber}
            onChange={handleChange}
            label="卡號"
            name="cardNumber"
            id="cardNumber"
            InputProps={{
              inputComponent: CardNumbeMask,
            }}
          />
          <div className={styles.group}>
            <TextField
              value={formData.expire}
              onChange={handleChange}
              label="到期日"
              name="expire"
              id="expire"
              InputProps={{
                inputComponent: ExpireMask,
              }}
            />
            <TextField
              value={formData.cvv}
              onChange={handleChange}
              label="CVV"
              name="cvv"
              id="cvv"
              InputProps={{
                inputComponent: CVVMask,
              }}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <SubmitButton disabled={isDisabled()} text="儲存" onClick={onSubmit} />
        </div>
      </div>
      <Dialog
        visible={visible}
        onConfirm={onConfirm}
        okButtonStyles={styles.okButton}
        message={
          <div className={styles.dialogHeader}>
            <p>設定成功</p>
          </div>
        }
      />
    </>
  );
};

export default CreditCard;
