import {
  GoogleMap,
  useJsApiLoader,
  Marker as RGMMarker,
  MarkerProps,
  // InfoBox,
  MarkerClusterer,
  InfoWindow, // 20230307 wilson add
  // DistanceMatrixService
} from '@react-google-maps/api';
import {
  Clusterer,
  ClusterIconStyle,
} from '@react-google-maps/marker-clusterer';
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import PinBox from 'assets/pin-box.svg';
import PinManual from 'assets/pin-manual.svg';
import PinHome from 'assets/homepin.svg';
import FamilyPin from 'assets/FamilyPinLogo.svg'; // 22.07.18 SCH
import styles from './index.module.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom'; // 21.12.20 SCH
// import IconClose from 'assets/icon-close.svg'; //20230306 wilson add
import {
  FC,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { useAuth } from 'context/Auth'; // 21.12.10 SCH
import { listServicePoints, ServicePointsData ,
  // RedislistServicePoints
} from 'api/query'; // 20230313 wilson add 新增RedislistServicePoints
import { listChannels } from 'api/query'; // 22.04.27 SCH
import SearchShop from './searchShop';
import BackToHome from './backToHome';
// import AreaScan from './areaScan'; // 20230313 Wilson add 新增一個區域搜尋的按鈕
import { GlobalContext } from '../../storage'; // 22.02.17 SCH
import { format, addDays } from 'date-fns';
import { isSameOrAfter, isSameOrBefore } from 'utils/utils';
// import { render } from 'react-dom';
// import App from '../../App'; // 22.05.20 SCH
import { CircularProgress } from "@mui/material";

// 20230307 wilson add
// 為了要控制地圖店家的小室窗使用
// https://stackoverflow.com/questions/37456627/how-to-change-the-infowindow-background-color

interface CustomMarkerProps extends MarkerProps {
  // lat: number;
  // lng: number;
  markerInfo: {
    id: number;
    name: string;
    channel_name: string;
    address: string;
    service_time: string;
    
  };
  infoVisible: boolean;
  returnType: 'box' | 'staff'; // 22.05.18 SCH
  navigate: NavigateFunction;
  returnable: boolean;
  chs_short_code: string; // 22.07.18 SCH
  chs_logo_pin_url: string

}

// const MockMarkers = [
//   {
//     latitude: 22.6341596,
//     longitude: 120.2858299,
//     id: 1,
//     name: '家樂福愛河店',
//     address: '807高雄市三民區河東路356號',
//     service_time: '13:00~17:00',
//     channel_name: 'test',
//   },
//   {
//     latitude: 22.6345678,
//     longitude: 120.2408299,
//     id: 2,
//     name: '家樂福愛河店2',
//     address: '807高雄市三民區河東路567號',
//     service_time: '13:00~17:00',
//     channel_name: 'test',
//   },
// ];

// const defaultProps = {
//   center: {
//     lat: 25.049396977003468,
//     lng: 121.52092431005671
//   },
//   zoom: 16,
// };
// 預設參考位置：台北火車站 25.048137625977215, 121.51705084128746

// 自動定位相關數值
// const [lat, setLat] = useState(null);
// const [lng, setLng] = useState(null);
const amazondomain='https://packageplusmainsys.s3-ap-northeast-1.amazonaws.com/';


const Marker: FC<CustomMarkerProps> = ({
  onClick,
  markerInfo,
  infoVisible,
  returnType, // 22.05.18 SCH
  navigate,
  returnable,
  chs_short_code, // 22.07.18 SCH
  chs_logo_pin_url,
  ...restProps
}) => (
  <>
    {infoVisible && (

      <InfoWindow
        position={restProps.position as google.maps.LatLng}
        options= {{
          maxWidth : 1200 , // 對應有變更 scss
          pixelOffset: new window.google.maps.Size(0, -40)
        }}

      >
        <div className={styles.infoModal} >
          <div className={styles.header}>
            <div className={styles.info}>
              <p className={styles.name}>{markerInfo.name}</p>
              <p className={styles.channel_name}>{markerInfo.channel_name}</p>
              <p className={styles.address}>{markerInfo.address}</p>
              <p className={styles.opentime}>{markerInfo.service_time}</p>
            </div>
            <div
              className={`${styles.tagContainer} ${
                returnable ? '' : styles.orange
              }`}
            >
              <div
              // onClick={()=>{alert('hello')}}

              >{returnable ? '尚可歸還' : '不可歸還'}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <div
            onClick={() => {
              // alert('navigate')
              navigate(`${markerInfo.id}`);
              console.log(`current span is ${markerInfo.id}`);
            }}
            >
              <button >
                <div>
                  <span>專屬優惠</span>
                </div>
                <ArrowSVG />
              </button>
            </div>
            <div
            onClick={() => {
              // alert('window.open')
              // let myAddress = markerInfo.address
              // let pos = myAddress.indexOf('號')
              // if (pos > 3) {myAddress = myAddress.slice(0,pos+1)}
              // console.log(myAddress)

              //結構上是長這樣沒錯，要// 因為中間會自動填起始地．專屬
              window.open(
                `https://www.google.com/maps/dir//${encodeURIComponent(
                    markerInfo.address
                )}`,
                '_blank'
              );
              console.log(`current map is ${markerInfo.id}`);
            }}
            >
              <button>
                <div>
                  <span>導航</span>
                </div>
                <ArrowSVG />
              </button>
            </div>
          </div>
        </div>
      </InfoWindow>
    )}
    <RGMMarker
      // icon={chs_short_code === 'fami' ? FamilyPin :
      //       returnType === 'box' ? PinBox : PinManual}
      icon={chs_short_code === 'fami' ? FamilyPin :
            chs_logo_pin_url !== null ? 
            amazondomain+chs_logo_pin_url:
            (returnType === 'box' ? PinBox : PinManual)}
      options={{ optimized: true }}
      position={restProps.position}
      clusterer={restProps.clusterer}
      onClick={onClick}
    />
  </>
);
const baseClusterIconStyle: Partial<ClusterIconStyle> = {
  textColor: '#D40511',
  fontFamily: 'Arial',
  fontStyle: 'normal',
  fontWeight: '700',
};

const smallClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGNpcmNsZSBjeD0iMjYiIGN5PSIyNiIgcj0iMTYiIGZpbGw9IiNGQzAiLz48Y2lyY2xlIGN4PSIyNiIgY3k9IjI2IiByPSIxNS4yNSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvZz48L3N2Zz4=',
  height: 52,
  width: 52,
  textSize: 16,
};

const mediumClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNjgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGNpcmNsZSBjeD0iMzQiIGN5PSIzNCIgcj0iMjQiIGZpbGw9IiNGQzAiLz48Y2lyY2xlIGN4PSIzNCIgY3k9IjM0IiByPSIyMyIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9zdmc+',
  height: 68,
  width: 68,
  textSize: 18,
};

const largeClusterIconStyle: ClusterIconStyle = {
  ...baseClusterIconStyle,
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iOTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PGNpcmNsZSBjeD0iNDUiIGN5PSI0NSIgcj0iMzUiIGZpbGw9IiNGQzAiLz48Y2lyY2xlIGN4PSI0NSIgY3k9IjQ1IiByPSIzMy41IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMyIvPjwvZz48L3N2Zz4=',
  height: 90,
  width: 90,
  textSize: 22,
};

const clusterIconStyles = [
  smallClusterIconStyle,
  mediumClusterIconStyle,
  largeClusterIconStyle,
];

// 20230308 wilson add  影響google map style
const myStyle = `
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c {
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: ;
  background-color: #fff0;
  border-radius: 8px;
  box-shadow: 0 0px 0px 0px rgba(0,0,0,0);
  padding: 0px;
}

.gm-ui-hover-effect {
  visibility: hidden;
}

.gm-style .gm-style-iw-t::after {
  background: #fff0;
}
.gm-style .gm-style-iw-tc::after {
  background: #fff0;
}
`;


let lastInfoWin;
const NearBy = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDBfUhuri4U29ygEqlbyQeL7D5GvvaO0QA',
    // 配客嘉 Google Maps API KEY AIzaSyDgSBCOBJGnaURu38szdsQGcX3r9wziT7o
    // 配客嘉 Google Places API KEY AIzaSyD6XniX3zIWSUIoa0vO7DQZAgzFZK1yUMA
  });

  const { accessToken, me } = useAuth(); // 21.12.10 SCH
  // const { pathname } = useLocation(); // 21.12.20 SCH
  const [servicePoints, setServicePoints] = useState<ServicePointsData[]>([]);
  const { nearByStorage } = useContext(GlobalContext); // 22.02.17 SCH
  const { setMeLatitude, setMeLongitude } = nearByStorage; // 22.05.19 SCH
  const { me_latitude, me_longitude } = nearByStorage; // 22.05.21 SCH
  const { channels, setChannels } = nearByStorage; // 22.04.27 SCH
  /* eslint-disable */
  const { spAreaAry, setSpAreaAry } = nearByStorage; // 22.05.21 SCH
  /* eslint-enable */
  const { chkDistance } = nearByStorage; // 22.05.21 SCH
  const { is_area, setIsArea } = nearByStorage; // 22.05.28 SCH
  const navigate = useNavigate();
  const mapRef = useRef<google.maps.Map>();
  const [load,setLoad]=useState<boolean>(false);
  
  // const refreshPage = () => {
  //   window.location.reload(); // 22.05.31 SCH
  // };
  
  useEffect(() => {

    // 20230313 wilson add 抓取GPS 位置
    // getLocation();

    if (channels.length === 0) {
      fetchChannels(); // 22.04.27 SCH
    }

    if (servicePoints.length === 0) { // 22.07.20 SCH
      //latitude longitude 過濾為小白加上
      if(me_latitude !== 0 && me_longitude !== 0){
        fetchServicePoints();
      }
    }

    // if (servicePoints.length === 0) { // 22.07.20 SCH
    //   setTimeout(() => {

    //     // 20230313 wilson add
    //     if(me_latitude && me_longitude){
    //         RedislistfetchServicePoints();
    //         // fetchServicePoints();
    //         console.log('RedislistfetchServicePoints:',me_latitude,me_longitude);
    //     }else{
    //         //fetchServicePoints(); // 22.05.21 SCH ●●● 必須執行!! 不能加入 deps:[]
    //         console.log('fetchServicePoints:',1);
    //     }
    //   }, 2000);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [channels.length,me_latitude,me_longitude]); // remove pathname // 22.05.28 SCH

  const fetchChannels = async () => {
    try {
      const { data } = await listChannels(accessToken!, 1, me?.id!);
      // console.log(data); // 22.04.27 SCH
      setChannels(data || []);
    } catch (error) {
      console.log(error);
    }
  };

    // 20230313 wilson add GPS 定位加速版抓取歸還點
    // const RedislistfetchServicePoints = async () => {
    //   try {
    //     const { data } = await RedislistServicePoints(accessToken!, 1, me?.id!, me_latitude ,me_longitude);
    //     if (data) {
    //       let tmp_Distance = 900000; // 900.km // 22.05.21 SCH
    //       let lc_area = localStorage.getItem('is_area');
    //       // console.log('is_area: ' + is_area + ', lc_area: ' + lc_area);
    //       if (is_area === 1 || lc_area === '1') {
    //         // 22.05.29 SCH
    //         if (chkDistance > 999) {
    //           tmp_Distance = chkDistance;
    //         }
    //         let chk_Distance = Number(
    //           JSON.parse(localStorage.getItem('chkDistance')!)
    //         );
    //         if (chk_Distance > chkDistance) {
    //           tmp_Distance = chk_Distance;
    //         }
    //       }
    //       computeDistance(data, tmp_Distance); // 22.05.21 SCH
    //       setServicePoints(data);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

  // 抓取所有符合條件的服務據點
  const fetchServicePoints = async () => {
    setLoad(true);
    try {
      // console.log(me_latitude,me_longitude);
      const { data } = await listServicePoints(accessToken!, 1, me?.id!,undefined,undefined,undefined,undefined,undefined,me_latitude,me_longitude);
      if (data) {
        setLoad(false);
        let tmp_Distance = 900000; // 900.km // 22.05.21 SCH
        let lc_area = localStorage.getItem('is_area');
        // console.log('is_area: ' + is_area + ', lc_area: ' + lc_area);
        if (is_area === 1 || lc_area === '1') {
          // 22.05.29 SCH
          if (chkDistance > 999) {
            tmp_Distance = chkDistance;
          }
          let chk_Distance = Number(
            JSON.parse(localStorage.getItem('chkDistance')!)
          );
          if (chk_Distance > chkDistance) {
            tmp_Distance = chk_Distance;
          }
        }
        computeDistance(data, tmp_Distance); // 22.05.21 SCH
        setServicePoints(data);
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };

  const computeDistance = (
    data: ServicePointsData[],
    checkDistance: number
  ) => {
    let spAry: any[] = [];
    let tmp_LatLng;
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      tmp_LatLng = {
        lat: item.latitude,
        lng: item.longitude,
        distance: 0,
        visible: 1,
        id: item.id,
        title: item.channel_name + '-' + item.name,
      };
      spAry.push(tmp_LatLng); // 22.05.21 SCH
    });
    let myPosition = {
      lat: me_latitude,
      lng: me_longitude,
      distance: 0,
      visible: 1,
      id: 0,
      title: '',
    };
    // console.log(myPosition);
    let tmp_Distance = 0;
    for (let i = 0; i < spAry.length; i++) {
      // 重點：計算兩點距離（目前參考位置 與 個別隨機標記 之間）
      tmp_Distance = getDistance(myPosition, spAry[i], checkDistance);
      spAry[i].distance = tmp_Distance;
      let sp_id = spAry[i].id; // 22.05.20 SCH
      let tmpSP = data.find((obj) => {
        return obj.id === sp_id;
      }); // 22.05.29 SCH
      if (tmpSP) {
        tmpSP.distance = tmp_Distance;
      } // 22.05.29 SCH
      if (tmp_Distance <= checkDistance) {
        spAry[i].visible = 1; // 將 範圍以內 的標記顯示出來
        if (tmpSP) {
          tmpSP.visible = 1;
        } // 22.05.29 SCH
      } else {
        spAry[i].visible = 0; // 將 不在範圍以內 的標記隱藏
        if (tmpSP) {
          tmpSP.visible = 0;
        } // 22.05.29 SCH
      }
    }
    setSpAreaAry(spAry); // 22.05.20 SCH
    localStorage.setItem('sp_Ary', JSON.stringify(spAry)); // 22.05.21 SCH
  };

  // 預設參考位置：台北火車站 25.048137625977215, 121.51705084128746
  type myLatLng = {
    lat: number;
    lng: number;
    distance: number;
    id: number;
    title: string;
  };
  const rad = function (x: number) {
    return (x * Math.PI) / 180;
  };

  const getDistance = function (p1: myLatLng, p2: myLatLng, cd: number) {
    let R = 6378137; // Earth’s mean radius in meter
    let dLat = rad(p2.lat - p1.lat);
    let dLong = rad(p2.lng - p1.lng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let strDistance = (R * c).toFixed(0);
    let distance = Number(strDistance);
    p2.distance = distance;
    // if ( distance <= cd ) { console.log(p2); }
    return distance; // return the distances in meter
  };

  /* eslint-disable */
  const [defaultProps, setDefaultProps] = useState<{
    center: {
      lat: number;
      lng: number;
    };
    zoom?: number;
  }>({
    // 預設位置：配客嘉公司所在位置的{lat,lng}(舊)
    // center: {
    //   lat: 25.049396977003468,
    //   lng: 121.52092431005671,
    // },
    // 預設位置：配客嘉公司所在位置的{lat,lng}(新)
    center: {
      lat: 25.0419686,
      lng: 121.5113899,
    },
    zoom: 16,
  });
  /* eslint-enable */

  // const defaultProps = {center: {
  //   lat: 25.0419686,
  //   lng: 121.5113899,
  // },
  // zoom: 16};

  // console.log("關閉視窗");
  const onMapClick = () => {
    // lastInfoWin = -1;
    setMarkerInfo({ visible: null, info: undefined });
  };

  // 執行 GPS 自動定位功能 auto center map base on user's geolocation
  const getLocation = useCallback(() => {
    // console.log('getLocation');
    if (!navigator.geolocation) {
      // setStatus('您的瀏覽器並未支援 GPS 自動定位功能。');
    } else {
      // setStatus('正在偵測您的位置...');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mapRef?.current?.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          mapRef?.current?.setZoom(16);
          // setDefaultProps({
          //   center: {
          //     lat: position.coords.latitude,
          //     lng: position.coords.longitude,
          //   },
          //   zoom: 16,
          // });
          // 22.02.18 SCH, Saved to storage
          setMeLatitude(position.coords.latitude);
          setMeLongitude(position.coords.longitude);
        },
        () => {
          // setStatus('無法取得您的所在位置');
        }
      );
    }
  }, [setMeLatitude, setMeLongitude]);

  const [markerInfo, setMarkerInfo] = useState<{
    visible: number | null;
    info?: {
      id: number; // 22.05.20 SCH
      name: string;
      channel_name: string;
      address: string;
      service_time: string;
    };
  }>({
    visible: null,
  });

  // 開啟或關閉[info]小視窗
  const onMarkerClick = (
    event: google.maps.MapMouseEvent,
    info: {
      id: number;
      name: string;
      channel_name: string;
      address: string;
      service_time: string;
      position: google.maps.LatLngLiteral;
    }
  ) => {
    mapRef?.current?.setCenter({
      lat: info.position.lat,
      lng: info.position.lng,
    });
    // event.stopPropagation();
    // event.preventDefault();
    if (markerInfo.visible === info.id) {
      lastInfoWin = -1; // 再點一次則關閉[info]小視窗
      setMarkerInfo({ visible: null, info: undefined });
    } else {
      setMarkerInfo({ visible: info.id, info });
      lastInfoWin = info.id;
      console.log('最後開啟：' + lastInfoWin);
    }
  };

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      mapRef.current = map;

      // 20230308 wilson add 安裝監聽器
      google.maps.event.addListenerOnce(map, 'idle', function(){
        // console.log('Package+ GO!');
      });

      getLocation();
    },
    [getLocation]
  );

  // 22.05.20 SCH, <div id='root' ...> ... </div>
  // const myContainer = document.querySelector('#myMap');
  // const myContainer = document.getElementById('myMap');

  // 點選某一服務據點後，自動置中在地圖上，並開啟[info]小視窗
  const onSearch = (shop: ServicePointsData | null) => {
    if (shop) {
      let lc_area = localStorage.getItem('is_area');
      if (lc_area === '1') {
        // 22.05.28 SCH (React 17.0.2)
        // fetchServicePoints(); // 22.07.20 SCH, 點掉!!
        console.log('before render(shop)');
        // render(<App />, myContainer); ???????
        console.log('after! render(shop)');
        setIsArea(0); // 22.05.28 SCH
        localStorage.setItem('is_area', '0');
      }
      // center map to selected shop
      // setDefaultProps({
      //   ...defaultProps,
      //   center: {
      //     lat: shop.latitude,
      //     lng: shop.longitude,
      //   },
      // });
      mapRef?.current?.setCenter({ lat: shop.latitude, lng: shop.longitude });
      // show shop marker info window
      // * marker should exist on map
      if (markerInfo.visible !== shop.id) {
        const info = {
          id: shop.id,
          name: shop.name,
          channel_name:
            shop.distance < 1000
              ? shop.channel_name + ' ( ' + shop.distance + ' m )'
              : shop.channel_name +
                ' ( ' +
                (shop.distance / 1000).toFixed(1) +
                ' km )', // 22.05.21 SCH
          address: shop.address,
          service_time: shop.service_time,
        };
        setMarkerInfo({
          visible: shop.id,
          info,
        });
      }
    } else {
      // 22.05.20 SCH, Do reRender here !!
      // filters?.area -> onChange(shop: null) in searchShop.tsx
      // fetchServicePoints(); // 22.07.20 SCH, 點掉!!
      onToHome(); // 22.05.29 SCH
      console.log('before render() or refreshPage()');
      // render(<App />, myContainer); // 22.05.21 SCH (React 17.0.2)
      // refreshPage(); // 22.05.31 SCH  ??????????
      console.log('after! render() or refreshPage()');
      // React 18.0 : ReactDOM.render(...) => ReactDOM.createRoot(...)
      // const myContainer = document.getElementById('root');
      // const root = createRoot(myContainer); // 22.05.28 SCH,有問題!!
      // root.render(<NearBy />);
    }
  };

  // 回到消費者原點(User.Location)
  const onToHome = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        mapRef?.current?.setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        mapRef?.current?.setZoom(16);
        // setDefaultProps({
        //   center: {
        //     lat: position.coords.latitude,
        //     lng: position.coords.longitude,
        //   },
        //   zoom: 16,
        // });

        //小白加
        setMeLatitude(position.coords.latitude);
        setMeLongitude(position.coords.longitude);
        console.log('onToHome:',me_latitude,me_longitude);
      },
      () => {
        // setStatus('無法取得您的所在位置');
      }
    );
    // if (home) {
    //   setDefaultProps({
    //     ...defaultProps,
    //     center: {
    //       lat: home.latitude,
    //       lng: home.longitude,
    //     },
    //   });
    // }
  };

  const renderMarkers = (clusterer?: Clusterer) => {
    return servicePoints!
      .filter(
        (servicePoint) =>
          servicePoint.latitude &&
          servicePoint.longitude &&
          servicePoint.enable === 1 &&
          servicePoint.visible !== 0
      )
      .map((sp, index) => {
        // if (!sp.latitude || !sp.longitude || sp.enable !== 1) {
        //   return null;
        // }
        // // 22.05.28 SCH ●●●
        // if (sp.visible === 0) {
        //   return null;
        // }
        // 22.05.28 SCH ●●●
        const info = {
          id: sp.id,
          name: sp.name,
          channel_name:
            sp.distance < 1000
              ? sp.channel_name + ' ( ' + sp.distance + ' m )'
              : sp.channel_name +
                ' ( ' +
                (sp.distance / 1000).toFixed(1) +
                ' km )', // 22.05.21 SCH
          address: sp.address,
          service_time: sp.service_time,
        };
        // 22.11.18 SCH, sp.service_time=null 則設為空字串
        if (!sp.service_time) {
          sp.service_time = ''; info.service_time = '';
        }
        let returnable = (sp.service_time.includes('24hr') || sp.service_time.includes('24小時'));
        // 09:00 - 23:00 for example ... // 22.07.06 SCH
        if (
          (!sp.service_time.includes('24hr') || !sp.service_time.includes('24小時')) &&
          //過濾掉空格
          sp.service_time.replace(/\s+/g, '').split('-').length === 2
        ) {
          try {
            const opentime = info.service_time.replace(/\s+/g, '').split('-')[0];
            const closetime = info.service_time.replace(/\s+/g, '').split('-')[1];
            const today = format(new Date(), 'yyyy/MM/dd');
            
            const openDateTime = new Date(`${today} ${opentime}:00`);
            let closeDateTime = new Date(`${today} ${closetime}:00`);
            const now = new Date();

            //如果closedatetime過隔夜，則加一天
            if (closetime < opentime) {
              closeDateTime = addDays(closeDateTime, 1);
            }

            if (
              isSameOrAfter(now, openDateTime) &&
              isSameOrBefore(now, closeDateTime)
            ) {
              returnable = true;
            }
          } catch (error) {
            console.log(error);
            returnable = false;
          }
        }
        
        return (
          <Marker
            key={sp.id}
            position={{
              lat: sp.latitude,
              lng: sp.longitude,
            }}
            onClick={(event) =>
              onMarkerClick(event, {
                ...info,
                position: {
                  lat: sp.latitude,
                  lng: sp.longitude,
                },
              })
            }
            markerInfo={info}
            infoVisible={markerInfo.visible === sp.id}
            returnType={sp.return_type} // 22.05.18 SCH
            returnable={returnable}
            chs_short_code={sp.chs_short_code} // 22.07.18 SCH
            navigate={navigate}
            clusterer={clusterer}
            chs_logo_pin_url={sp.chs_logo_pin_url}
          />
        );
      });
  };
  
  // 藍色定位點，小白加上
  const homeMarkers=()=>{
    return(
      <div className={styles.aa}>
      <RGMMarker
        icon={ PinHome}
        options={{ optimized: true }}
        position={{
          lat: me_latitude,
          lng: me_longitude,
        }}
      />
      </div>
    )
  }

  //小白加上，先隱藏
  // useEffect(()=>{
  //   let loc=setInterval(()=>{
  //     if (!navigator.geolocation) {
  //     // setStatus('您的瀏覽器並未支援 GPS 自動定位功能。');
  //   } else {
  //     // setStatus('正在偵測您的位置...');
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         // 22.02.18 SCH, Saved to storage
  //         setMeLatitude(position.coords.latitude);
  //         setMeLongitude(position.coords.longitude);
  //         console.log('a')
  //       },
  //       () => {
  //         // setStatus('無法取得您的所在位置');
  //       }
  //     );
  //   }
  //   },4000);
  //   return ()=>{clearInterval(loc)};
  // },[]);

  return (
    <div id="myMap" className={styles.nearby}>
      <style>{myStyle}</style>
      <SearchShop onChange={onSearch} />
      <BackToHome onPress={onToHome} />
      {/* 20230313 Wilson add 區域範圍搜尋 */}
      {/* <AreaScan onPress={onToHome} /> */}

      {/* loading progress */}
      {load && <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>}

      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          options={{
            fullscreenControl: false,
            gestureHandling: 'greedy',
            clickableIcons: true,
            disableDefaultUI: true,
          }}
          onClick={onMapClick}
          center={defaultProps.center}
          zoom={defaultProps.zoom}
          onLoad={onLoad}
        >
          {servicePoints && (
            <MarkerClusterer
              averageCenter
              maxZoom={15}
              options={{ styles: clusterIconStyles }}
            >
              {(clusterer) => <>{renderMarkers(clusterer) as any}</>}
            </MarkerClusterer>
          )}
          {/* 小白加上定位 */}
          <MarkerClusterer
              averageCenter
              maxZoom={15}
              options={{ styles: clusterIconStyles }}
            >
              {() => <>{homeMarkers() as any}</>}
          </MarkerClusterer>
        </GoogleMap>
      )}
      {/* <DistanceMatrixService
        options={{
           destinations: [{lat:1.296788, lng:103.778961}],
           origins: [{lng:103.780267, lat:1.291692}],
           travelMode: "DRIVING" as any,
         }}
        callback = {(response) => {console.log(response)}}
      /> */}
    </div>
  );
};

export default NearBy;
