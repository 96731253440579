import {
  FC,
  SyntheticEvent,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from '@mui/material/styles/styled';
import styles from './index.module.scss';
import { Autocomplete } from '@mui/material';
import { useAuth } from 'context/Auth';
// import { listServicePoints, ServicePointsData } from 'api/query'; //20230112 wilson add no GPS 版本使用


import { familistServicePoints, FamiServicePointsData } from 'api/query'; // 20230112 wilson add

import { chkEpkId } from 'api/return'; // 21.12.23 SCH
import { GlobalContext } from '../../storage'; // 21.12.23 SCH



const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  marginTop: 10,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginBottom: 20,
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minWidth: 285,
  padding: '20px',
  paddingBottom: 28,
}));

const DialogActions = styled(MuiDialogActions)(() => ({
  padding: '0 20px',
  paddingBottom: 18,
  display: 'flex',
  flexDirection: 'column',
  '& > :not(:first-of-type)': {
    marginLeft: 0,
    marginTop: 10,
  },
}));



interface ManualModalProps {
  visible: boolean;
  step: number;
  // onConfirm: (data?: ServicePointsData | string) => void;
  onConfirm: (data?: FamiServicePointsData | string) => void;
  onClose?: () => void;
}

const ManualModal: FC<ManualModalProps> = (props) => {
  /* eslint-disable */
  const { visible, onConfirm, step, onClose } = props;
  const { accessToken, me } = useAuth(); // 21.12.10 SCH
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  // const [selectedPoint, setSelectedPoint] = useState<ServicePointsData>(); //20230112 wilson add no GPS 版本使用
  // const [servicePoints, setServicePoints] = useState<ServicePointsData[]>(); //20230112 wilson add no GPS 版本使用
  const [selectedPoint, setSelectedPoint] = useState<FamiServicePointsData>();//20230112 wilson add
  const [servicePoints, setServicePoints] = useState<FamiServicePointsData[]>();//20230112 wilson add
  const [packageId, setPackageId] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null); // 21.12.25 SCH
  const [epkIdFound, setEpkIdFound] = useState<boolean>(false);
  const { returnStorage } = useContext(GlobalContext); // 21.12.23 SCH
  const { store_id, setStoreId } = returnStorage; // 21.12.23 SCH
  const { chs_short_code, setChsShortCode } = returnStorage; // 21.12.23 SCH
  const { epk_id, setEpkId } = returnStorage; // 21.12.23 SCH
  const { fami_tage, setFamiTage } = returnStorage; // 20230110 Wilson


  const epkId = useRef<string>(); // 21.12.24 SCH

  const { nearByStorage } = useContext(GlobalContext); // 20230112 wilson add
  const { me_latitude, me_longitude } = nearByStorage;
  /* eslint-enable */


  useEffect(() => {

    // 20230112 wilson add
    // 如果有抓取到授權位置，才可以呼叫
    // if(me_latitude != 0 && me_longitude != 0  ){
    //     fetchServicePoints();
    // }
    // eslint-disable-next-line

  });
  useEffect(() => {
    if (!visible) {
      onAutoCompleteClose();
      setSelectedPoint(undefined);
    }
  }, [visible]);

  // 20230111 wilson
  // 抓取資料庫所有店家的資料
  const fetchServicePoints = async () => {
    try {
      setLoading(true);


      // const data = await listServicePoints(
      const data = await familistServicePoints(
        accessToken!,
        1,
        me?.id!,
        '2',// 20230116 wilson add 全家 fami
        // me_latitude,
        // me_longitude

        );
      setServicePoints(
        data?.data
          ?.filter(
            (marker) =>
              marker.latitude && marker.longitude && marker.enable === 1
          )
          .map((marker) => {
            // let info: ServicePointsData = {
            let info: FamiServicePointsData = {
              id: marker.id,
              latitude: marker.latitude,
              longitude: marker.longitude,
              // name: '【' + marker.channel_name + '】' + marker.name + '：' + marker.address, // 20230111 wilson change
              name: '【' + marker.channel_name + '】' + marker.name ,
              channel_name: marker.channel_name,
              address: marker.address,
              service_time: marker.service_time,
              enable: marker.enable,
              store_id: marker.store_id, // 21.12.23 SCH
              chs_short_code: marker.chs_short_code, // 21.12.23 SCH
              // TODO: change to Real Data
              return_type: marker.return_type, // 22.05.14 SCH
              discount_type: marker.discount_type, // 22.05.16 SCH
              distance: 0, // 22.05.20 SCH
              visible: 1, // 22.05.20 SCH
            };
            return info;
          })
      );
      setLoading(false);



    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const findEpkId = async () => {
    try {
      console.log('packageId：' + packageId);
      console.log('epkId：' + epkId.current);
      const { data } = await chkEpkId(accessToken!, 1, {
        epk_id: epkId.current,
      });
      if (data) {
        console.log(data);
        // 21.12.24 SCH, 將資料存入到 storage !!
        console.log('epk_id：' + data?.epk_id);
        setEpkId(data?.epk_id); // 21.12.24 SCH
        setEpkIdFound(true);
      } else {
        setEpkIdFound(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onOpen = async () => {
    setOpen(true);
    // 20230112 wilson change
    // 點擊對話筐才抓取，資料庫。
    setLoading(true);
    await fetchServicePoints();
    setLoading(false);
  };
  const onAutoCompleteClose = () => {
    setServicePoints([]);
    setOpen(false);
  };

  const onSelected = (
    event: SyntheticEvent<Element, Event>,
    // value: ServicePointsData | null
    value: FamiServicePointsData | null
  ) => {
    if (value) {
      setSelectedPoint(value);
      console.log('wilson value :', value);
      // 21.12.24 SCH, 將資料存入到 storage !!
      setChsShortCode(value.chs_short_code);
      setStoreId(value.store_id);
      // onConfirm();
    }
  };

  // step === 0 代表在最源頭要使用選擇全家位置
  const handleOnConfirm = () => {
    if (step === 0) {
      onConfirm(selectedPoint);

      console.log('selectedPoint:'+ selectedPoint);

      console.log('chs_short_code:'+chs_short_code);
      console.log('store_id:'+store_id); // 21.12.24 SCH, OK
      setFamiTage(true);
      // 2023.01.10 wilson add
      // 設定轉換顯前控制

      return;
    }
    onConfirm(selectedPoint);
    setPackageId(undefined);
    setEpkIdFound(false);
  };


  // console.log('wilson servicePoints:'+ JSON.stringify(servicePoints));
  // const [value, setValue] = useState<string | null>('1');

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>
        {step === 0 ? '請選擇歸還全家店名' : '請輸入包裝物品編號'}
      </DialogTitle>
      <DialogContent>
        {step === 0 ? (
          <div className={styles.manualContainer}>
            <Autocomplete
              // value={value}
              sx={{
                display: 'flex',
                flex: 1,
                width: '100%',
                alignItems: 'center',
                '& > div': {
                  width: '100%',
                },
                '& input': {
                  width: '100%',
                  outline: 'none',
                  fontSize: 16,
                  border: '1px solid #00000029',
                  height: 58,
                  padding: '0 10px',
                },
              }}

              // open={open}
              onOpen={onOpen}
              onClose={onAutoCompleteClose}
              options={servicePoints || []}
              noOptionsText="無歸還點"
              onChange={onSelected}
              loading={loading}
              loadingText="載入中..."
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    type="text"
                    placeholder="請下拉選取"
                    {...params.inputProps}
                  />
                </div>
              )}
            />
            {selectedPoint && (
              <div className={styles.selectedInfo}>
                <span>歸還點：{selectedPoint?.name}</span>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.manualContainer}>
            <input
              type="text"
              pattern="[A-Z0-9]*"
              ref={inputRef} // 21.12.25 SCH
              className={styles.manualInput}
              onChange={(event) => {
                setPackageId(event.target.value as any);
                epkId.current = event.target.value;
                if (epkId.current) {
                  if (epkId.current.length >= 6) {
                    findEpkId();
                  }
                }
              }}
            />
            {epkIdFound && (
              <div className={styles.selectedInfo}>
                <span>已找到包裝物品編號：{epk_id}</span>
              </div>
            )}
            {!epkIdFound && (
              <div className={styles.selectedInfo}>
                <span>請輸入循環使用購物袋(包裝箱)編號</span>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {(selectedPoint || epkIdFound) && (
          <button
            className={`${styles.actionButton}`}
            onClick={handleOnConfirm}
          >
            確定
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ManualModal;
