import React, { useEffect } from 'react';
// import { Player } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as ReturnBoxSVG } from 'assets/icon-returnbox.svg';
import FamiReturnInstructionsPNG from 'assets/fami_return_instructions.png';// 20230113 wilson add
import { useLocation, useNavigate } from 'react-router';
import styles from './index.module.scss';

const Rent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // let source = require('../../assets/line-return.mp4').default;

  useEffect( () => {
  }, [pathname])

  return (
    <div className={styles.rent}>


    <div

    >

      <div>
        <img style={{ width: 320,height: 370,left: 7,top: 96 }} src={FamiReturnInstructionsPNG} alt="PNG"/>

      </div>
    </div>

    <div
      className={`${styles.menu} ${styles.descriptiongreen}`}
      onClick={() => {
        navigate('/return');
      }}
    >
      <div>
        <span>點我開始歸還</span>
        <span>有借有還，循環不難！</span>
      </div>
      <div>
        <ReturnBoxSVG />
      </div>
    </div>

    <div
      className={`${styles.menu} ${styles.lookaroundgray}`}
      onClick={() => {
        navigate('/nearby');
      }}
    >
    <div>
      <span>查看附近</span>
      </div>
        <ArrowSVG />
      </div>
    </div>
  );
};

export default Rent;
