import { CircularProgress, SelectChangeEvent} from '@mui/material';
import React, {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Select from '../../components/Select';
import {ReactComponent as SearchSVG} from 'assets/icon-search.svg';
import {ReactComponent as BoxSVG} from 'assets/box.svg';
import {ReactComponent as GiveSVG} from 'assets/give.svg';
import {ReactComponent as ReturnBoxSVG} from 'assets/icon-box.svg';
import {ReactComponent as ReturnSVG} from 'assets/icon-return.svg';
import styles from './index.module.scss';
import {GlobalContext} from '../../storage'; // 22.04.27 SCH
import {listChannels, listServicePoints, ServicePointsData} from 'api/query';
import {useAuth} from 'context/Auth'; // 21.12.10 SCH
import Districts from 'utils/taiwan_districts.json'; // 縣市名稱及鄉鎮市區名稱
import {BottomSheet} from '../../components/BottomSheet';
import {isSameOrAfter, isSameOrBefore} from 'utils/utils';
import {format,addDays} from 'date-fns';
import { Autocomplete } from '@mui/material'; // 22.07.06 SCH
import {SyntheticEvent} from 'react'; // 22.07.06 SCH
// import {useLocation} from "react-router-dom";

// 22.05.17 SCH, yarn add react-google-maps "react-google-maps": "^9.4.5",
// import {Circle, Marker} from "react-google-maps";

// const MockOptions: ServicePointsData[] = [
//   {
//     id: 1,
//     latitude: 25.08417777,
//     longitude: 121.5225806,
//     name: '【7-ELEVEN】三樂',
//     address: '台北市士林區劍潭路21號',
//     service_time: '24hr營業',
//     channel_name: '7-11',
//     enable: 1,
//     store_id: "131984", // 21.12.23 SCH
//     chs_short_code: "7-11",
//   },
//   {
//     id: 2,
//     latitude: 25.09553481,
//     longitude: 121.52607608,
//     name: '【7-ELEVEN】福榮',
//     address: '台北市士林區中正路220.222號',
//     service_time: '24hr營業',
//     channel_name: '7-11',
//     enable: 1,
//     store_id: "171764", // 21.12.23 SCH
//     chs_short_code: "7-11",
//   },
// ];

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

let _servicePoints: ServicePointsData[];

interface FiltersFormData {
  returnType?: string;
  openTime?: string[];
  shop?: string; // channels.id.string (通路)
  city?: string;
  area?: string; // 幾公里內
  discountType?: string; // 22.05.16 SCH
}

let channelOption =
    [
        {label: '全部', value: '0'},
        {label: '7-ELEVEN', value: '1'},
        {label: '全家', value: '2'},
        {label: '家樂福', value: '3'},
        {label: '成真咖啡', value: '8'},
        {label: 'OK超商', value: '14'},
    ];

const SearchShop = ({
  onChange,
}: {
  onChange: (value: ServicePointsData | null) => void;
}) => {
  const { accessToken, me } = useAuth(); // 21.12.10 SCH
  // const { pathname } = useLocation(); // 22.05.20 SCH
  const [servicePoints, setServicePoints] = useState<ServicePointsData[]>([]);
  const [filters, setFilters] = useState<FiltersFormData>();
  /* eslint-disable */
  const [search, setSearch] = useState<string>('');
  /* eslint-enable */
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);
  const { nearByStorage } = useContext(GlobalContext); // 22.04.27 SCH
  const { me_latitude, me_longitude } = nearByStorage; // 22.05.18 SCH
  const { channels, setChannels } = nearByStorage; // 22.05.20 SCH
  const { spAreaAry, setSpAreaAry } = nearByStorage; // 22.05.20 SCH
  const { setChkDistance } = nearByStorage; // 22.05.21 SCH
  const { setIsArea } = nearByStorage; // 22.05.28 SCH
  const query4area = useRef<boolean>(false); // 22.05.18 SCH
  const totShops = useRef<number>(0); // 22.05.18 SCH
  const [open, setOpen] = useState<boolean>(false); // 22.07.06 SCH
  const [options, setOptions] = useState<ServicePointsData[]>([]);


  useEffect(() => {
    if (channels.length === 0) {
      fetchChannels(); // 22.05.20 SCH
      getAllServicePoints(); // 22.07.06 SCH
    }
    if (servicePoints.length === 0) { // 22.07.20 SCH
      fetchServicePoints(); // 不能加入 deps:[]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels.length]);
  // remove pathname // 22.05.28 SCH
  
  const fetchChannels = async () => {
    try {
      const { data } = await listChannels(accessToken!, 1, me?.id!);
      // console.log(data); // 22.04.27 SCH
      setChannels(data || []);
    } catch (error) {
      console.log(error);
    }
  };

  
  // 22.07.06 SCH
  const tuneSPoints = (data:ServicePointsData[] | undefined)=> {
    if(!data)
      return;
    // console.log("調整資料");
    _servicePoints = []; // 22.02.18 SCH
    for(let i = 0; i < data.length; i++) {
      let marker = data[i];
      if(marker.latitude && marker.longitude && marker.enable === 1) {
        // console.log(marker);
        let info : ServicePointsData = {
          id: marker.id,
          latitude : marker.latitude,
          longitude : marker.longitude,
          name: '【' + marker.channel_name + '】' + marker.name + '：' + marker.address,
          channel_name: marker.channel_name,
          address: marker.address,
          service_time: marker.service_time,
          enable: marker.enable,
          store_id: marker.store_id,
          chs_short_code: marker.chs_short_code,
          return_type: marker.return_type, // 22.07.06 SCH
          discount_type: marker.discount_type, // 22.07.06 SCH
          distance: marker.distance, // 22.07.06 SCH
          visible: marker.visible, // 22.07.06 SCH
          chs_logo_pin_url:marker.chs_logo_pin_url
          
        };
        _servicePoints.push(info);
      }
    }
  };
  // 22.07.06 SCH
  const getAllServicePoints = async () => {
    try {
      const data = await listServicePoints(accessToken!, 1, me?.id!);
      let data2:ServicePointsData[]|undefined = data.data;
      tuneSPoints(data2);
    } catch (error) {
      console.log(error);
    }
  };

  // 抓取所有符合條件的服務據點
  const fetchServicePoints = async () => {
    try {
      setLoading(true);
      const { data } = await listServicePoints(accessToken!, 1, me?.id!,
          filters?.city, filters?.returnType, filters?.shop, filters?.openTime,
          filters?.discountType); // 22.05.18 SCH
      // console.log(data);
      if (data) {
        if (spAreaAry) {
          if (spAreaAry.length > 0) { // 22.05.21 SCH
            for (let i = 0; i < spAreaAry.length; i++ ) {
              let sp_id = spAreaAry[i].id;
              let tmpSP = data.find(obj => {return obj.id === sp_id;});
              if (tmpSP) { tmpSP.distance = spAreaAry[i].distance; }
            }
          } else {
            try {
              let sp_Ary = JSON.parse(localStorage.getItem("sp_Ary")!);
              if (sp_Ary) {
                if (sp_Ary.length > 0) { // 22.05.21 SCH
                  for (let i = 0; i < sp_Ary.length; i++) {
                    let sp_id = sp_Ary[i].id;
                    let tmpSP = data.find(obj => {return obj.id === sp_id;});
                    if (tmpSP) { tmpSP.distance = sp_Ary[i].distance; }
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
        // setServicePoints(data || []);
        setServicePoints(data); // 22.05.28 SCH
      }
      // console.log(data); // 22.05.21 SCH
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // 關掉查詢滑動小表頭(closeContainer)
  const toggleSearch = () => {
    channelOption = channels;
    // console.log(channels);
    setShow(!show);
    // setServicePoints([]); // 22.05.18 SCH, 點掉(不清空)
    if (show) {
      setFilters(undefined);
      setShowList(false);
    }
  };
  
  /* eslint-disable */
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  /* eslint-enable */

  const onBottomSheetClose = () => {
    setShowList(false);
  };
  
  const onSelectedShop = (shop: ServicePointsData) => {
    onChange(shop); // 22.05.20 SCH, trigger!!
    toggleSearch();
  };
  const onFilterChange = (name: string, event: SelectChangeEvent<unknown>) => {
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
    if (name !== 'area' && name !== 'city' && !filters?.city) {
      setFilters({
        ...filters,
        [name]: event.target.value,
        'city': 'all', // 22.05.28 SCH, trigger!!
      });
    }
    if (name === 'area') {
      setIsArea(1); // 22.05.28 SCH
      localStorage.setItem("is_area", "1");
      onChange(null); // 22.05.20 SCH, trigger!!
    } else {
      // setIsArea(0); // 22.05.28 SCH
      // localStorage.setItem("is_area", "0");
    }
  };

  // trigger by filters?.* // 22.05.18 SCH
  useEffect(() => {
    if (filters?.city || filters?.shop || filters?.returnType ||
        filters?.openTime || filters?.discountType) {
      // TODO: city is changed than you can do some logic here
      fetchServicePoints(); // 不能加入 deps:[]
      setShowList(true);
      if (query4area.current) {
        query4area.current = false;
        setShowList(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.city, filters?.shop, filters?.returnType,
           filters?.openTime, filters?.discountType]);

  // trigger by filters?.area // 22.05.17 SCH 查詢範圍(By.距離)
  useEffect(() => {
      if (filters?.area && filters?.area !== 'all') {
          // TODO: area is changed than you can do some logic here
        fetchServicePoints(); // 不能加入 deps:[]
        let checkDistance = parseInt(filters.area) * 1000; // 檢查有效距離（單位：公尺）
        // console.log(checkDistance + ' 公尺');
        query4area.current = true; setShowList(false);
        totShops.current = 0;
        drawCircle(servicePoints, checkDistance); // 不能加入 deps:[]
        // console.log(servicePoints); // 22.05.20 SCH
        toggleSearch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.area, servicePoints]);

  const drawCircle = (data: ServicePointsData[], checkDistance: number) => {
    // console.log(checkDistance + ' meters');
    let spAry: any[] = [];
    let tmp_LatLng;
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      tmp_LatLng = { lat: item.latitude, lng: item.longitude, distance: 0,
        visible: 1, id: item.id, title: item.channel_name+'-'+item.name };
      spAry.push(tmp_LatLng); // 22.05.18 SCH
    });
    let myPosition = { lat: me_latitude, lng: me_longitude,
        distance: 0, visible: 1, id: 0, title: ''};
    // console.log(myPosition);

    // // 圓形有效範圍（參考用，不一定要真的畫出來）
    // const cityCircle = new Circle({
    //   // strokeColor: "#FF0000",
    //   // strokeOpacity: 0.8,
    //   // strokeWeight: 2,
    //   // fillColor: "#FF0000",
    //   // fillOpacity: 0.1,
    //   // map,
    //   center: myLatLng,
    //   radius: checkDistance,
    // });
    // console.log(cityCircle);

    let tmp_Distance = 0;
    for (let i = 0; i < spAry.length; i++ ) {
      // 重點：計算兩點距離（目前參考位置 與 個別隨機標記 之間）
      tmp_Distance = getDistance(myPosition, spAry[i], checkDistance);
      spAry[i].distance = tmp_Distance;
      let sp_id = spAry[i].id; // 22.05.20 SCH
      let tmpSP = servicePoints.find(obj => {return obj.id === sp_id;}); // 22.05.28 SCH
      if (tmpSP) { tmpSP.distance = tmp_Distance; } // 22.05.28 SCH
      if ( tmp_Distance <= checkDistance ) {
        totShops.current += 1;
        // console.log(tmp_Distance + ' 公尺, 累計(' + totShops.current + ')筆');
        spAry[i].visible = 1;  // 將 範圍以內 的標記顯示出來
        if (tmpSP) { tmpSP.visible = 1; } // 22.05.28 SCH
      } else {
        spAry[i].visible = 0; // 將 不在範圍以內 的標記隱藏
        if (tmpSP) { tmpSP.visible = 0; } // 22.05.28 SCH
      }
    }
    setSpAreaAry(spAry); // 22.05.20 SCH
    setChkDistance(checkDistance); // 22.05.21 SCH
    localStorage.setItem("sp_Ary", JSON.stringify(spAry)); // 22.05.21 SCH
    localStorage.setItem("chkDistance", checkDistance.toString()); // 22.05.21 SCH
  };

  // 預設參考位置：台北火車站 25.048137625977215, 121.51705084128746
  type myLatLng = { lat: number, lng: number,
                    distance: number, id: number, title: string};
  const rad = function (x: number) {
    return x * Math.PI / 180;
  };
  const getDistance = function (p1: myLatLng, p2: myLatLng, cd: number) {
    let R = 6378137; // Earth’s mean radius in meter
    let dLat = rad(p2.lat - p1.lat);
    let dLong = rad(p2.lng - p1.lng);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let strDistance = (R * c).toFixed(0);
    let distance = Number(strDistance);
    p2.distance = distance;
    // if ( distance <= cd ) { console.log(p2); } // 22.07.02 SCH
    return distance; // return the distances in meter
  };

  const onOpen = async () => {
    setOpen(true);
    setLoading(true);
    await sleep(2000);
    // console.log(_servicePoints);
    setOptions(_servicePoints);
    setLoading(false);
  };
  const onClose = () => {
    setOptions([]);
    setOpen(false);
  };
  const onSelected = (
      event: SyntheticEvent<Element, Event>,
      value: ServicePointsData | null
  ) => {
    if (value) {
      onChange(value);
      setShow(false);
    }
  };

  return (
    <>
      <div className={`${styles.searchContainer} ${show ? styles.active : ''}`}>
        <div className={styles.search}>
          <div className={styles.inputContainer}>
            <div className={styles.autocompleteContainer}>
              <Autocomplete
                sx={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  '& > div': {
                    width: 'calc(100% - 79px)',
                    marginLeft: '20px',
                  },
                  '& input': {
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    fontSize: 15,
                    '&::placeholder': {
                      color: '#999999',
                    },
                  },
                }}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                options={options}
                noOptionsText="無租借/歸還點"
                onChange={onSelected}
                loading={loading}
                loadingText="載入中..."
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                          type="text"
                          placeholder="搜尋租借/歸還點"
                          {...params.inputProps}
                      />
                    </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className={styles.filters}>
          <Select
            placeholder="歸還方式"
            value={filters?.returnType || ''}
            options={[
              { label: '全部', value: 'all' },
              {
                label: '投入歸還箱',
                value: 'box',
                icon: <BoxSVG style={{ marginRight: 5 }} />,
              },
              {
                label: '交給店員',
                value: 'staff',
                icon: <GiveSVG style={{ marginRight: 5 }} />,
              },
            ]}
            menuMaxWidth
            onChange={(event: SelectChangeEvent<unknown>) => onFilterChange('returnType', event)}
          />
          <Select
            placeholder="營業時間"
            multiple
            value={filters?.openTime || []}
            options={[
              [
                { label: '週一', value: '1mon' },
                { label: '週二', value: '2tue' },
                { label: '週三', value: '3wed' },
                { label: '週四', value: '4thu' },
                { label: '週五', value: '5fri' },
                { label: '週六', value: '6sat' },
                { label: '週日', value: '0sun' },
              ],
              [
                { label: '早上 07~12時', value: 'rangeA' },
                { label: '下午 13~18時', value: 'rangeB' },
                { label: '晚上 19~24時', value: 'rangeC' },
                { label: '凌晨 00~06時', value: 'rangeD' },
              ],
            ]}
            onChange={(event: SelectChangeEvent<unknown>) => onFilterChange('openTime', event)}
          />
          <Select
            placeholder="通路"
            value={filters?.shop || ''}
            options={channelOption} // 22.04.27 SCH
            menuMaxWidth
            onChange={(event: SelectChangeEvent<unknown>) => onFilterChange('shop', event)}
          />
          <Select
            placeholder="縣市(必填)"
            value={filters?.city || ''}
            options={[
              { label: '全部', value: 'all' },
              ...Districts.map((city) => ({
                label: city.value,
                value: city.value,
              })),
            ]}
            menuMaxWidth
            onChange={(event: SelectChangeEvent<unknown>) => onFilterChange('city', event)}
          />
          <Select
            placeholder="查詢範圍"
            value={filters?.area || ''}
            options={[
              { label: '全部', value: 'all' },
              { label: '3公里內', value: '3' },
              { label: '5公里內', value: '5' },
              { label: '7公里內', value: '7' },
              { label: '9公里內', value: '9' },
            ]}
            menuMaxWidth
            onChange={(event: SelectChangeEvent<unknown>) => onFilterChange('area', event)}
          />
          <Select
            placeholder="優惠"
            value={filters?.discountType || ''}
            options={[
              { label: '全部', value: 'all' },
              { label: '商品兌換', value: 'product' },
              { label: '優惠碼兌換', value: 'code' },
            ]}
            menuMaxWidth
            onChange={(event: SelectChangeEvent<unknown>) => onFilterChange('discountType', event)}
          />
        </div>

        <div className={styles.closeContainer}>
          <button
            onClick={() => {
              toggleSearch();
            }}
          >
            <CloseIcon />
          </button>
        </div>

      </div>

      <div
        className={`${styles.searchButtonContainer} ${
          show ? styles.active : ''
        }`}
      >
        <button
          onClick={() => {
            toggleSearch();
          }}
        >
          <SearchSVG />
        </button>
      </div>

      <BottomSheet
        open={showList}
        peekHeights={[loading ? 145 : 307]}
        onClose={onBottomSheetClose}
        hideHeader={loading}
        loading={loading}
      >
        {loading ? (
          <div className={styles.loadingContainer}>
            <CircularProgress size={101} />
          </div>
        ) : (
          <div className={styles.shopList}>
            {servicePoints.map((point) => {
              // 22.11.18 SCH, sp.service_time=null 則設為空字串
              if (!point.service_time) { point.service_time = ''; }
              let returnable = (point.service_time.includes('24hr') || point.service_time.includes('24小時'));
              // 09:00 - 23:00 for example ... // 22.07.06 SCH
              if (
                (!point.service_time.includes('24hr') || !point.service_time.includes('24小時')) &&
                //過濾掉空格
                point.service_time.replace(/\s+/g, '').split('-').length === 2
              ) {
                try {
                  const opentime = point.service_time.replace(/\s+/g, '').split('-')[0];
                  const closetime = point.service_time.replace(/\s+/g, '').split('-')[1];
                  // const today = format(new Date('Sat Sep 14 2024 18:18:40 GMT+0800 (台北標準時間)'), 'yyyy/MM/dd');
                  const today = format(new Date(), 'yyyy/MM/dd');
                  
                  const openDateTime = new Date(`${today} ${opentime}:00`);
                  let closeDateTime = new Date(`${today} ${closetime}:00`);
                  // const now = new Date('Sat Sep 14 2024 18:42:40');
                  const now = new Date();
                  
                  //如果closedatetime過隔夜，則加一天
                  if (closetime < opentime) {
                    closeDateTime = addDays(closeDateTime, 1);
                  }

                  // if(point.week_6sat_ranges === 'E'){
                  //   returnable = false;
                  // }

                  if (
                    isSameOrAfter( now,openDateTime ) &&
                    isSameOrBefore( now,closeDateTime )
                  ) {
                    returnable = true;
                  }
                
                } catch (error) {
                  console.log(error);
                  returnable = false;
                }

                
              }

              return (
                <div
                  key={point.id}
                  className={styles.shopItem}
                  onClick={() => onSelectedShop(point)}
                >
                  <div
                    className={`${styles.icon} ${
                      point.return_type === 'box' ? styles.box : styles.staff
                    }`}
                  >
                    {point.return_type === 'box' ? (
                      <ReturnBoxSVG />
                    ) : (
                      <ReturnSVG />
                    )}
                  </div>
                  <div className={styles.shopDetail}>
                    <div>
                      <h3>{point.name}</h3>
                      <div
                        className={`${styles.tagContainer} ${
                          returnable ? '' : styles.orange
                        }`}
                      >
                        <div>{returnable ? '尚可歸還' : '不可歸還'}</div>
                      </div>
                    </div>
                    <div>
                      <span>{point.address}</span>
                    </div>
                    <div>
                      <span>{point.service_time}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </BottomSheet>
    </>
  );
};

export default SearchShop;
