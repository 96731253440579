import { ReactComponent as ArrowSVG } from 'assets/icon-arrow-right.svg';
import {useEffect, useRef, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styles from './discountInfo.module.scss';
import {getVoucher, VoucherData} from "../../api/query";
import {useAuth} from "../../context/Auth";
import {updateUserVoucherLogs} from "../../api/return";
import Dialog from 'components/Dialog';
import discountInfoImage from 'assets/discountInfo.jpg'; // 22.07.05 SCH

const DiscountInfo = () => {
  const [type, setType] = useState<'online' | 'offline'>('online');
  const { discountId } = useParams();
  const { pathname } = useLocation();
  const { accessToken } = useAuth(); // 22.05.27 SCH
  const [voucher, setVoucher] = useState<VoucherData>();
  const redeemCode = useRef<string>(''); // 22.05.27 SCH
  const typeRef = useRef<string>('online'); // 22.05.30 SCH
  const nameRef = useRef<string>('前往'); // 22.05.30 SCH
  const messageRef = useRef<string>(''); // 22.05.30 SCH
  const [finishVisible, setFinishVisible] = useState<boolean>(false);
  const [doubleCheckVisible, setDoubleCheckVisible] = useState<boolean>(false);
  const pictureRef = useRef<string>(''); // 22.05.30 SCH
  let imgPreUrl = 'https://packageplusmainsys.s3-ap-northeast-1.amazonaws.com/';
  let due_start = '即日起'; // 22.07.05 SCH
  let due_end = '無期限'; // 22.07.05 SCH

  // TODO: using discountID to fetch single coupon and get its coupon type (online/offline)
  useEffect(() => {
    fetchVoucher(); // 22.05.27 SCH
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  console.log(type);

  const fetchVoucher = async () => {
    try {
      const { data } = await getVoucher(accessToken!, 1, discountId!);
      // console.log(data);
      if (!data) {
        return 'Voucher not found !!';
      } else {
        // FIXME: change back to data for display real data from server
        switch (data.use_type) {
          case 1:
            redeemCode.current = data.redeem_code;
            setType('online'); typeRef.current = 'online';
            nameRef.current = '本店';
            if (data.chs_short_code === '7-11') { nameRef.current = 'ibon'; }
            if (data.chs_short_code === 'fami') { nameRef.current = 'FamiPort'; }
            if (data.chs_short_code === 'okmart') { nameRef.current = 'ＯＫ'; }
            break;
          case 2:
            redeemCode.current = data.pin_code;
            setType('online'); typeRef.current = 'online';
            nameRef.current = '前往'; break;
          case 3:
            redeemCode.current = '立即兌換';
            setType('offline'); typeRef.current = 'offline';
            break;
          case 4:
            redeemCode.current = data.pin_code;
            setType('online'); typeRef.current = 'online';
            nameRef.current = '前往'; break;
        }
        let image_url = data.picture_url ? data.picture_url.toString() : 'null';
        if (image_url.includes('http')) {
          // skip
        } else {
          // 22.07.05 SCH, data.picture_url 有可能為 'null'
          if (image_url.length > 4) {
            image_url = imgPreUrl + image_url;
          } else {
            image_url = discountInfoImage ; // 22.07.05 SCH
          }
        }
        pictureRef.current = image_url;
        setVoucher(data); // 22.05.30 SCH
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 22.05.30 SCH, copy from offlineDiscount.tsx
  const changeUserVoucherLogs = async () => {
    if (voucher?.redeem_url) {
      if (voucher?.redeem_url.includes('http')) {
        window.open(voucher?.redeem_url); // 22.05.30 SCH
      }
    }
    try {
      const { data } = await updateUserVoucherLogs(accessToken!, 1, {
        id: Number(discountId),
        userVoucherLog_id: Number(discountId),
      });
      console.log(data);
      messageRef.current = '兌換成功'; // 22.05.30 SCH
      setFinishVisible(true) // 22.05.30 SCH
    } catch (error) {
      console.log(error);
    }
    // refreshPage(); // 22.05.30 SCH
  };

  const onCopy = () => {
    if (typeRef.current === 'offline') {
      setDoubleCheckVisible(true); // 22.07.06 SCH
    } else {
      if (!navigator.clipboard) {
        return; // Clipboard API not available
      }
      navigator.clipboard
        .writeText(redeemCode.current)
        .then(() => {
          console.log('copy successful');
        })
        .catch((err) => {
          console.log('Something went wrong', err);
        });
    }
  };

  // 22.07.06 SCH
  const showDoubleCheckConfirm = () => {
    setDoubleCheckVisible(true);
  }
  // 22.07.06 SCH
  const onDoubleCheckConfirm = () => {
    setDoubleCheckVisible(false);
    changeUserVoucherLogs();
    messageRef.current = '兌換成功';
    setFinishVisible(true)
  };
  // 22.07.06 SCH
  const onCancelButtonClick = () => {
    setDoubleCheckVisible(false);
  };
  // 22.07.06 SCH
  const onCancel= () => {
    setDoubleCheckVisible(false);
  };

  // 22.05.30 SCH
  const onfinishConfirm = () => {
    window.history.back(); // 回上一頁
  };

  return (
    <>
      <div className={styles.discountInfo}>
        <div className={styles.imgWrap}>
          <div className={styles.imgInner}>
            <img src={pictureRef.current} alt="" className={styles.img} />
          </div>
        </div>
        <div className={styles.redeemCode}>
          {typeRef.current === 'online' ? (
            <div className={styles.code}>
              <h3>{redeemCode.current}</h3>
              <span>複製代碼，點擊下方按鈕，{nameRef.current}兌換</span>
            </div>
          ) : (
            <div className={styles.code}>
              <h3>請向店員出示此畫面</h3>
              <h3>以兌換此券</h3>
            </div>
          )}
          <div className={styles.copyBtn}>
            <button onClick={onCopy}>
              {typeRef.current === 'online' ? '複製代碼' : '確認兌換'}
            </button>
          </div>
        </div>
        <div className={styles.extra}>
          <div className={styles.dates}>
            兌換期限：{voucher?.start_at === null ? due_start : voucher?.start_at.slice(0,10)} ~ {voucher?.expired_at === null ? due_end : voucher?.expired_at.slice(0,10)}
          </div>
          <div className={styles.description}>
            {voucher?.title}<br/><br/>
            {voucher?.description}<br/>
            {voucher?.specification}
          </div>
          {typeRef.current === 'online' && (
            <button className={styles.btn} onClick={
              showDoubleCheckConfirm // 22.07.06 SCH
            }>
              <span>{nameRef.current}兌換</span>
              <ArrowSVG />
            </button>
          )}
          <div className={styles.message}>
            {messageRef.current}
          </div>
        </div>
      </div>
      <Dialog
        visible={doubleCheckVisible}
        message={
          <div className={styles.message}>
            <h2>是否兌換？</h2>
          </div>
        }
        onCancel={onCancel}
        onConfirm={onDoubleCheckConfirm}
        onCancelButtonClick={onCancelButtonClick}
        cancelable
        cancelText="取消"
        okText="確認"
      />
      <Dialog
          visible={finishVisible}
          onConfirm={onfinishConfirm}
          message={
            <div className={styles.message}>
              <h2>兌換完成</h2>
            </div>
          }
          okText="我知道了"
          okButtonStyles={styles.okButtonStyles}
      />
    </>
  );
};

export default DiscountInfo;
